export class CONSTANTS {
  public static readonly ACCESS_TOKEN = 'access';
  public static readonly ACCOUNT = 'account';
  public static readonly ACCOUNT_GROUP = 'AccountGroup';
  public static readonly ACCOUNT_GROUP_DESCRIPTION = 'accountGroupDescription';
  public static readonly ACCOUNT_GROUP_NAME = 'accountGroupName';
  public static readonly ACCOUNT_GROUPS = 'accountGroups';
  public static readonly ACCOUNT_HAS_DEPENDENCIES = 'ACCOUNT_HAS_DEPENDENCIES';
  public static readonly ACCOUNTS = 'accounts';
  public static readonly ACTIVE_SESSION = 'ACTIVE_SESSION';
  public static readonly ACTIVITIES_NOT_FOUND = 'ACTIVITIES_NOT_FOUND';
  public static readonly ACTIVITY_PICKING_LIST = 'Picking List';
  public static readonly ACTIVITY_PICKING_PALLET = 'Picking Pallet';
  public static readonly ADD = 'add';
  public static readonly ADD_ANOTHER = 'addAnother';
  public static readonly ADD_LPN = 'add_lpn';
  public static readonly ADD_PRODUCT = 'addProduct';
  public static readonly ADDRESS_CANNOT_BE_UPDATED = 'ADDRESS_CANNOT_BE_UPDATED';
  public static readonly ALL_COLUMNS = 'col-md-12';
  public static readonly ALLOW_CUSTOMIZED_PICKING_LIST = 'allow_customized_picking_list';
  public static readonly ALLOW_RECEIPT_BY_EACH = 'allow_receipt_by_each';
  public static readonly ANOTHER_TASK = 'anotherTask';
  public static readonly API_ALREADY_EXISTS = 'API_ALREADY_EXISTS';
  public static readonly API_DESCRIPTION_MAX_LENGTH = 250;
  public static readonly API_NAME_MAX_LENGTH = 150;
  public static readonly APPLICATION_ZIP = 'application/zip';
  public static readonly APPOINTMENT_CONFIGURATION_EXCEEDED_QUANTITY = 'APPOINTMENT_CONFIGURATION_EXCEEDED_QUANTITY';
  public static readonly APPOINTMENT_CONFIGURATION_NOT_FOUND = 'APPOINTMENT_CONFIGURATION_NOT_FOUND';
  public static readonly APPOINTMENT_CONFIGURATION_RESTRICTION_IN_QUANTITY = 'APPOINTMENT_CONFIGURATION_RESTRICTION_IN_QUANTITY';
  public static readonly APPOINTMENT_DIFFERENT_ORDER_POLICIES = 'APPOINTMENT_DIFFERENT_ORDER_POLICIES';
  public static readonly APPOINTMENT_FOLIO = 'appointmentFolio';
  public static readonly APPOINTMENT_RECEIPT_WITH_ASN = 'appointment_receipt_with_asn';
  public static readonly AREA = 'area';
  public static readonly AREA_NOT_FOUND = 'AREA_NOT_FOUND';
  public static readonly AREA_TYPE = '00000000000000000';
  public static readonly AREAS = 'areas';
  public static readonly ARRAY_BUFFER = 'arraybuffer';
  public static readonly ARRAY_HEAD = 0;
  public static readonly ARROW_BACK = 'back_arrow';
  public static readonly ARROW_PATH_ICON = '../../../../../assets/icons/return_arrow.svg';
  public static readonly ASCENDANT_ORDER = 'asc';
  public static readonly ASK_FOR_KITTING_COMPONENTS_PARAMETER = 'ask_for_kitting_components';
  public static readonly ASN_QUANTITY_GREATER_THAN_ORDERED = 'ASN_QUANTITY_GREATER_THAN_ORDERED';
  public static readonly ASSIGNED_TASK_NOT_FOUND = 'ASSIGNED_TASK_NOT_FOUND';
  public static readonly ASSIGNMENT_ROUTE = '/assignment-policy';
  public static readonly ATTR1 = 'attr1';
  public static readonly ATTR2 = 'attr2';
  public static readonly ATTR3 = 'attr3';
  public static readonly AUDIT_MODULE = 20;
  public static readonly AUDIT_NOT_FOUND = 'AUDIT_NOT_FOUND';
  public static readonly AUDIT_OPTION_STEP = 'auditOptionStep';
  public static readonly AUDIT_STEP = 'auditStep';
  public static readonly AUDIT_TYPE_STEP = 'auditTypeStep';
  public static readonly AUTHORIZATION = 401;
  public static readonly BARCODE_CONFIGURATION_NOT_FOUND = 'BARCODE_CONFIGURATION_NOT_FOUND';
  public static readonly BARCODE_STEP = 'barcodeStep';
  public static readonly BILL_NUMBER = 'billNumber';
  public static readonly BILLING_ADRESS = 'billingAddress';
  public static readonly BLANK_SPACE = ' ';
  public static readonly BOXES = 'boxes';
  public static readonly BULK_PICKING = 'BulkPicking';
  public static readonly BULK_PICKING_AREA_TYPE = '00000000000000110';
  public static readonly BULK_PICKING_BY = 'bulkPickingBy';
  public static readonly BULK_PICKING_BY_ORDER = 'bulkPickingByOrder';
  public static readonly BULK_PICKING_BY_WAVE = 'bulkPickingByWave';
  public static readonly BULK_PICKING_CANNOT_CREATED = 'BULK_PICKING_CANNOT_CREATED';
  public static readonly BULK_PICKING_FIRST_ATTRIBUTE_STEP = 'bulk_first_attribute_step';
  public static readonly BULK_PICKING_LOT_STEP = 'bulk_lot_step';
  public static readonly BULK_PICKING_NOT_FOUND = 'BULK_PICKING_NOT_FOUND';
  public static readonly BULK_PICKING_SECOND_ATTRIBUTE_STEP = 'bulk_second_attribute_step';
  public static readonly BULK_PICKING_SERIAL_STEP = 'serial_step';
  public static readonly BULK_PICKING_TASK_IN_PROGRESS = 'BULK_PICKING_TASK_IN_PROGRESS';
  public static readonly BULK_PICKING_THIRD_ATTRIBUTE_STEP = 'bulk_third_attribute_step';
  public static readonly BYTE_ORDER_MARK = '\ufeff';
  public static readonly CANCEL_SHIPMENT = 'cancel_shipment';
  public static readonly CANCEL_SHIPMENT_ORDER_ACTIVITY = 'Cancel Shipment Order';
  public static readonly CANCELLED = 'Cancelled';
  public static readonly CANNOT_CUT_PICKING_LIST = 'CANNOT_CUT_PICKING_LIST';
  public static readonly CARRIER = 'carrier';
  public static readonly CASE = 'case';
  public static readonly CASE_ID_TYPE = 'Case Id';
  public static readonly CASE_TRANSFER = 'CaseTransfer';
  public static readonly CATALOG_ACCOUNT_EMPTY_ARRAY = 'CATALOG_ACCOUNT_EMPTY_ARRAY';
  public static readonly CATALOG_CARRIER_CANNOT_BE_FOUND = 'CATALOG_CARRIER_CANNOT_BE_FOUND';
  public static readonly CATALOG_FAMILY_EMPTY_ARRAY = 'CATALOG_FAMILY_EMPTY_ARRAY';
  public static readonly CATALOG_FAMILY_HAS_DEPENDENCIES = 'CATALOG_FAMILY_HAS_DEPENDENCIES';
  public static readonly CATALOG_SUBFAMILY_FAIL_DELETE = 'CATALOG_SUBFAMILY_FAIL_DELETE';
  public static readonly CATALOG_WAREHOUSE_NOT_FOUND = 'CATALOG_WAREHOUSE_NOT_FOUND';
  public static readonly CATALOGS_API_INTEGRATION = 'CATALOGS_API_INTEGRATION';
  public static readonly CATEGORY = 'category';
  public static readonly CENT_PORCENT = 100;
  public static readonly CHANGE_DASHBOARD = 120000;
  public static readonly CHANGE_PASSWORD_PATH = 'change-password';
  public static readonly CLICK = 'click';
  public static readonly CLOSE_AUDIT_STEP = 'closeAuditStep';
  public static readonly CLOSE_PICKING_STEP = 'closePicking';
  public static readonly CLOSE_SHIPMENT = 'close_shipment';
  public static readonly CLOSED_STATUS = 'Closed';
  public static readonly CODE_CHANGE_MODULE = 18;
  public static readonly COLON = ':';
  public static readonly COLOR_BLUE = '#0f71c2';
  public static readonly COLOR_GREEN = '#47a019';
  public static readonly COLOR_GREY = '#a0a0a0';
  public static readonly COLOR_PURPLE = '#844abd';
  public static readonly COLOR_RED = '#bf0629';
  public static readonly COLOR_WHITE = '#efefef';
  public static readonly COLOR_YELLOW = '#ffbf04';
  public static readonly COLORS: any = { red: { primary: '#b71c1c' } };
  public static readonly COMMA = ',';
  public static readonly COMMON_AREA_TYPE = '10000000000000000';
  public static readonly COMPLETE_STATUS = 'completeStatus';
  public static readonly COMPLETE_STYLE = 'complete';
  public static readonly COMPONENT_PREFIX = 'Component:';
  public static readonly CONFIRM_CANCEL_TASK = 'confirmCancelTask';
  public static readonly CONFIRM_DOOR = 'confirm_door';
  public static readonly CONFIRM_LPN = 'confirmLpn';
  public static readonly CONFIRM_PASSWORD = 'confirmPassword';
  public static readonly CONFIRM_PICK = 'confirmPick';
  public static readonly CONFIRM_SKU = 'confirmSku';
  public static readonly CONFIRMLPN = 'confirmLpn';
  public static readonly CONTAINS_MATCH_MODE = 'contains';
  public static readonly COUNT_BY_EACH = 'count_by_each';
  public static readonly COUNT_NUMBER = 'count_number';
  public static readonly CREATE = 'create';
  public static readonly CREATE_APPOINTMENT = 'receipt-appointment/add';
  public static readonly CREATE_LABEL_GUIDE_INTERFACE = 'create_label_guide_interface';
  public static readonly CREATE_LABEL_MAGENTO_INTERFACE = 'create_label_magento_interface';
  public static readonly CREATED_STATUS = 'Created';
  public static readonly CSV_DATE = 'YYYY-MM-DD';
  public static readonly CSV_DATE_FORMAT = 'DD/MM/YYYY';
  public static readonly CURRENCY = 'currency';
  public static readonly CURRENCY_NOT_FOUND = 'CURRENCY_NOT_FOUND';
  public static readonly CUSTOM_CELL = 'custom-cell';
  public static readonly CUSTOM_LOADING_TIMEOUT = 2000;
  public static readonly CUSTOMER_GROUPS = 'customerGroups';
  public static readonly CUSTOMER_ID = 'customer.id';
  public static readonly CUSTOMER_SHIPPING_DESTINATION = 'destination';
  public static readonly CUSTOMERS_NOT_AVAILABLE_FOR_LABEL_CONFIGURATION = 'CUSTOMERS_NOT_AVAILABLE_FOR_LABEL_CONFIGURATION';
  public static readonly CUSTOMS = 'customs';
  public static readonly CYCLE_COUNT_LOCATION_NOT_FOUND = 'CYCLE_COUNT_LOCATION_NOT_FOUND';
  public static readonly DASH = '-';
  public static readonly DASHBOARD_MIN_ROWS_TO_ROTATE = 3;
  public static readonly DASHBOARD_VISIBLE_ROWS = 6;
  public static readonly DATE = 'Date';
  public static readonly DATE_FORMAT = 'YYYY-MM-DD';
  public static readonly DATE_FORMAT_REGEXP = /[\d-]/;
  public static readonly DATE_RANGE = 'dateRange';
  public static readonly DATE_TIME_FORM_KEY = 'timeExpirationDate';
  public static readonly DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
  public static readonly DATE_TIME_FORMAT_REVERSE = 'DD-MM-YYYYTHH:mm:ss';
  public static readonly DATE_TIMEZONE = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';
  public static readonly DAY = 'day';
  public static readonly DAY_NAME_MIN_WEDNESDAY = 3;
  public static readonly DAYS = 'days';
  public static readonly DAYS_PASSWORD_CHANGE = 'days_password_change';
  public static readonly DECIMAL_MASK = '0*.00';
  public static readonly DECIMAL_MASK_NOT_ALLOWED_DECIMALS = 'separator.0';
  public static readonly DECIMAL_ROUNDED = 10;
  public static readonly DECREASE = 1;
  public static readonly DEFAULT_DECIMAL_MASK_ALLOWED_DECIMALS = 'separator.2';
  public static readonly DEFAULT_DECIMAL_MASK_MAX_LENGTH = '99999999';
  public static readonly DEFAULT_DECIMAL_MASK_THOUSAND_SEPARATOR = ' ';
  public static readonly DEFAULT_LOCATION = 0;
  public static readonly DEFAULT_LOCATION_FONT_SIZE = 1.5;
  public static readonly DEFAULT_MOBILE_INPUT = 'DEFAULT_MOBILE_INPUT';
  public static readonly DEFAULT_NAVIGATE_TIME = 2500;
  public static readonly DEFAULT_STYLE = 'default';
  public static readonly DEFAULT_TEXT = 'defaultText';
  public static readonly DEFAULT_TIMER = '--:--:--';
  public static readonly DEFAULT_WEIGHT = 1;
  public static readonly DESCENDANT_OPTION = 'desc';
  public static readonly DESTINATION = 'destination';
  public static readonly DESTINATION_ADRESS = 'destinationAddress';
  public static readonly DESTINATION_AREA = 'destinationArea';
  public static readonly DESTINATION_LOCATION = 'destinationLocation';
  public static readonly DESTINATION_LPN = 'destinationLpn';
  public static readonly DESTINATION_PRODUCT = 'destinationProduct';
  public static readonly DETAIL_PUT_AWAY = 'DetailPutAway';
  public static readonly DETAILS_STEP = 'details';
  public static readonly DIAGONAL = '/';
  public static readonly DISPLAY_NONE = 'display: none';
  public static readonly DISPLAY_TIME_FUNCT = 3000;
  public static readonly DIVIDE = 'divide';
  public static readonly DOOR = 'door';
  public static readonly DOOR_AREA_TYPE = '00001000000000000';
  public static readonly DOOR_LOCATIONS_NOT_FOUND = 'DOOR_LOCATIONS_NOT_FOUND';
  public static readonly DOT = '.';
  public static readonly DOWNLOAD_INVENTORY_RECONCILIATION = 'Download Inventory Reconciliation';
  public static readonly DOWNSEQUENCE = 'downSequence';
  public static readonly DRIVER = 'driver';
  public static readonly DYNAMIC_ATTRIBUTE_1 = 'dynamicAttribute1';
  public static readonly DYNAMIC_ATTRIBUTE_1_CRITERIA = 'dynamic_attribute_1';
  public static readonly DYNAMIC_ATTRIBUTE_2 = 'dynamicAttribute2';
  public static readonly DYNAMIC_ATTRIBUTE_2_CRITERIA = 'dynamic_attribute_2';
  public static readonly DYNAMIC_ATTRIBUTE_3 = 'dynamicAttribute3';
  public static readonly DYNAMIC_ATTRIBUTE_3_CRITERIA = 'dynamic_attribute_3';
  public static readonly EDITABLE = 'editable';
  public static readonly EDITABLE_CELL = 'editable-cell';
  public static readonly EIGHT = 8;
  public static readonly ELEMENT_A = 'a';
  public static readonly EMAIL_PATTERN = '[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$';
  public static readonly EMPTY = 'Empty';
  public static readonly EMPTY_REJECTION_RECEIPT_TEMPORAL = 'EMPTY_REJECTION_RECEIPT_TEMPORAL';
  public static readonly EMPTY_STRING = '';
  public static readonly EMPTY_VALUE = '';
  public static readonly EMTPY_CHARACTER_UNICODE = '\u034F';
  public static readonly ENGLISH_LANGUAGE = 'en-US';
  public static readonly EQUALS_MATCH_MODE = 'equals';
  public static readonly EQUIPMENT_AREA_TYPE = '00000000000001000';
  public static readonly EQUIPMENT_ID = 'equipment_id';
  public static readonly EQUIPMENT_LOCATION = 1;
  public static readonly EQUIPMENT_NOT_FOUND = 'EQUIPMENT_NOT_FOUND';
  public static readonly EQUIPMENT_PC = 1;
  public static readonly EQUIPMENT_TYPE_NOT_FOUND = 'EQUIPMENT_TYPE_NOT_FOUND';
  public static readonly EVENT = '$event';
  public static readonly EXCEL_IMPORT_FORM = ['.XLSX', '.XLS', '.CSV'];
  public static readonly EXPIRATION_DATE = 'expirationDate';
  public static readonly EXPIRATION_DATE_AS_STRING = 'expirationDateAsString';
  public static readonly EXPIRATION_DATE_STRING = 'expirationDateString';
  public static readonly EXPIRATIONDATE = 'expirationDate';
  public static readonly EXPIRATIONTIME = 'expirationTime';
  public static readonly EXPORT_DELIMITERS = /[,]/g;
  public static readonly EXTERNAL_WAREHOUSE = 'externalWarehouse';
  public static readonly FALSE = 'false';
  public static readonly FIFTY = 50;
  public static readonly FIFTY_NINE = 59;
  public static readonly FILE_TYPE = '.zpl';
  public static readonly FILE_UPLOAD_FAILED = 'FILE_UPLOAD_FAILED';
  public static readonly FINISH_STATUS = 'finishStatus';
  public static readonly FINISH_STYLE = 'finish';
  public static readonly FINISHED = 'Finished';
  public static readonly FIRST_ARRAY_POSITION = 0;
  public static readonly FIRST_COUNT = 1;
  public static readonly FIRST_INDEX = 1;
  public static readonly FIRST_STEP = 0;
  public static readonly FIRST_WAREHOUSE = 0;
  public static readonly FIVE = 5;
  public static readonly FOLIO = 'FOLIO';
  public static readonly FOLIO_FORM = 'folio';
  public static readonly FOLIO_NOT_FOUND_IN_COUNT = 'FOLIO_NOT_FOUND_IN_COUNT';
  public static readonly FOLIO_STEP = 'folioStep';
  public static readonly FOOTPRINT = 'footprint';
  public static readonly FOOTPRINT_DETAIL = 'footprintDetail';
  public static readonly FOOTPRINT_DETAIL_ALREADY_EXISTS = 'FOOTPRINT_DETAIL_ALREADY_EXISTS';
  public static readonly FOOTPRINT_DETAILS_NOT_FOUND = 'FOOTPRINT_DETAILS_NOT_FOUND';
  public static readonly FOOTPRINT_DISPLAY = 'footprint_display';
  public static readonly FORTY_FIVE = 45;
  public static readonly FOUR = 4;
  public static readonly FOUR_COLUMNS = 4;
  public static readonly FULL_LOCATION_CAPACITY = 'FULL_LOCATION_CAPACITY';
  public static readonly FULL_LOCATION_HOLD_CAPACITY = 'FULL_LOCATION_HOLD_CAPACITY';
  public static readonly GATE = 'gate';
  public static readonly GENERAL_MODULE = 11;
  public static readonly GENERAL_MODULE_STRING = 'General';
  public static readonly GRANDSIRE = 'grandsireTime';
  public static readonly GREY_TEXT = 'greyText';
  public static readonly GUIDE = 'guide';
  public static readonly GUIDE_GENERATED_BY_INTEGRATOR = 'GUIDE_GENERATED_BY_INTEGRATOR';
  public static readonly GUIDES_REQUEST_TIMEOUT = 'GUIDES_REQUEST_TIMEOUT';
  public static readonly HASH = '#';
  public static readonly HOME_ROUTE = 'home';
  public static readonly HOUR = 'appointmentStartHour';
  public static readonly HOUR_FORMAT = 'HH:mm';
  public static readonly HOUR_MINUTES_FORMAT = 'HH:mm:ss';
  public static readonly HUNDRED_PERCENT = 100;
  public static readonly HYPHEN_DIAGONAL = ' / ';
  public static readonly ID = 'id';
  public static readonly ID_TOKEN = 'token';
  public static readonly IMPORT_DATE = 'importDate';
  public static readonly IMPORT_NUMBER = 'importNumber';
  public static readonly IMPORT_TEMPLATE_ROLE = 'Import Template Shipment Order';
  public static readonly IN_PROCESS_STATUS = 'InProcess';
  public static readonly IN_PROGRESS_STATUS = 'inProgressStatus';
  public static readonly IN_PROGRESS_STYLE = 'inProgress';
  public static readonly INDEX_DETAIL = 0;
  public static readonly INDEX_NOT_FOUND = -1;
  public static readonly INDEXZERO = 0;
  public static readonly INDIVIDUAL_PRODUCTIVITY_REPORTS_NOT_FOUND = 'INDIVIDUAL_PRODUCTIVITY_REPORTS_NOT_FOUND';
  public static readonly INIT_DATE_FORMAT = 'DD-MM-YYYY';
  public static readonly INITIAL_SHIPMENT_LPN = 0;
  public static readonly INPUT = 'input';
  public static readonly INPUT_FIELD_CONFIGURATION_CANNOT_FOUND = 'INPUT_FIELD_CONFIGURATION_CANNOT_FOUND';
  public static readonly INPUT_FIELD_CONFIGURATION_FILENAME = 'inputFieldConfiguration';
  public static readonly INPUT_FIELD_PACK_STATION_AREA_ID = 5;
  public static readonly INPUT_FIELD_PACK_STATIONS_TASK = 'Packstation';
  public static readonly INPUT_STYLE = { 'width': '100%' };
  public static readonly INPUT_TIMEOUT = 250;
  public static readonly INTEGRATOR = 8;
  public static readonly INTEGRATOR_ERROR = 'INTEGRATOR_ERROR';
  public static readonly INTEGRATOR_MODULE = 8;
  public static readonly INVALID_CAPACITY_CODE = 'INVALID_CAPACITY_CODE';
  public static readonly INVALID_ORIGIN_DESTINATION_LOCATION = 'INVALID_ORIGIN_DESTINATION_LOCATION';
  public static readonly INVALID_PASSWORD_LENGTH = 'INVALID_PASSWORD_LENGTH';
  public static readonly INVALID_PIECES_SCANNED = 'invalid_pieces';
  public static readonly INVALID_PIECES_SCANNED_ICON_PATH = 'assets/icons/packstation/invalid_pieces.svg';
  public static readonly INVALID_QUANTITY_ENTERED = 'INVALID_QUANTITY_ENTERED';
  public static readonly INVENTORY = 'inventory';
  public static readonly INVENTORY_COUNT = 'inventoryCount';
  public static readonly INVENTORY_COUNT_NOT_FOUND = 'INVENTORY_COUNT_NOT_FOUND';
  public static readonly INVENTORY_COUNT_REGEXP = /[1234]+/g;
  public static readonly INVENTORY_COUNT_REGEXP_PICKING = /[123]+/g;
  public static readonly INVENTORY_DETAIL_UPDATE_FAILED = 'INVENTORY_DETAIL_UPDATE_FAILED';
  public static readonly INVENTORY_HEADER_CANNOT_BE_FOUND = 'INVENTORY_HEADER_CANNOT_BE_FOUND';
  public static readonly INVENTORY_HEADER_FOUND = 'INVENTORY_HEADER_FOUND';
  public static readonly INVENTORY_LOAD_DIALOG_WIDTH = '38vw';
  public static readonly INVENTORY_LOAD_FILE_ERRORS = 'INVENTORY_LOAD_FILE_ERRORS';
  public static readonly INVENTORY_LOAD_TEMPLATE_PATH = 'assets/import-file/inventory-load-template.csv';
  public static readonly INVENTORY_LOCATION_ALREADY_IN_COUNT = 'INVENTORY_LOCATION_ALREADY_IN_COUNT';
  public static readonly INVENTORY_MODULE = 5;
  public static readonly INVENTORY_NOT_FOUND_ASSIGNMENT_ZONE_USER = 'INVENTORY_NOT_FOUND_ASSIGNMENT_ZONE_USER';
  public static readonly INVENTORY_REPORT_LOCATION_SCREEN = 'Reporte de inventario por localidad';
  public static readonly INVENTORY_ROTATION = 'inventoryRotation';
  public static readonly INVENTORY_SKU_AUTOCOMPLETE_PARAM = 'inventory_sku_autocomplete_confirm';
  public static readonly INVENTORY_STATUS = 'inventoryStatus';
  public static readonly INVENTORY_STATUS_DEFAULT_ID = 1;
  public static readonly IS_ACTIVE = 'isActive';
  public static readonly IS_AUTOMATIC_SHIPMENT = 'is_automatic_shipment';
  public static readonly IS_BULK_RECEIPT = 'isBulkReceipt';
  public static readonly IS_CASE_ID_REQUIRED_FORM = 'isCaseIdRequired';
  public static readonly IS_CASE_LEVEL = 'isCaseLevel';
  public static readonly IS_CONSOLIDATE_BY = 'isConsolidateBy';
  public static readonly IS_CONSOLIDATE_BY_DESTINATION = 'isConsolidatedByDestination';
  public static readonly IS_CONSOLIDATE_BY_STAGE = 'isConsolidatedByStage';
  public static readonly IS_CONSOLIDATE_BY_WAVE = 'isConsolidateByWave';
  public static readonly IS_DETAIL_PUT_AWAY = 'is_detail_put_away';
  public static readonly IS_EACH_LEVEL = 'isEachLevel';
  public static readonly IS_EXPRESS = 'isExpress';
  public static readonly IS_FIRST_DYNAMIC_ATTRIBUTE_REQUIRED_FORM = 'isDynamicAttribute1Required';
  public static readonly IS_FIRST_DYNAMIC_ATTRIBUTE_VALID = 'isDynamicAttribute1Valid';
  public static readonly IS_FULL_EXPIRATION_DATE = 'is_full_expiration_date';
  public static readonly IS_INTERNATIONAL = 'isInternational';
  public static readonly IS_LOCATION_REQUIRED_FORM = 'isLocationRequired';
  public static readonly IS_LOT_REQUIRED_FORM = 'isLotRequired';
  public static readonly IS_LOT_VALID = 'isLotValid';
  public static readonly IS_LPN_REQUIRED_FORM = 'isLpnRequired';
  public static readonly IS_NOT_NULL = 'isNotNull';
  public static readonly IS_ONLINE = 'isOnline';
  public static readonly IS_PACKAGE_LEVEL = 'isPackageLevel';
  public static readonly IS_PALLET_LEVEL = 'isPalletLevel';
  public static readonly IS_PART_ID_REQUIRED_FORM = 'isPartIdRequired';
  public static readonly IS_PARTIAL = 'isPartial';
  public static readonly IS_PICKING_LOT_AUDIT = 'is_picking_lot_audit';
  public static readonly IS_PIECE_AUDIT = 'is_piece_audit';
  public static readonly IS_QUANTITY_REQUIRED_FORM = 'isQuantityRequired';
  public static readonly IS_REQUIRED_AUDIT = 'is_required_audit';
  public static readonly IS_REQUIRED_EMPTY = 'isRequiredEmpty';
  public static readonly IS_SECOND_DYNAMIC_ATTRIBUTE_REQUIRED_FORM = 'isDynamicAttribute2Required';
  public static readonly IS_SECOND_DYNAMIC_ATTRIBUTE_VALID = 'isDynamicAttribute2Valid';
  public static readonly IS_SERIAL_ONLY_IN_PICKING = 'is_serial_only_in_picking';
  public static readonly IS_SERIAL_REQUIRED_FORM = 'isSerialRequired';
  public static readonly IS_SERIAL_VALID = 'isSerialValid';
  public static readonly IS_SKU_REQUIRED_FORM = 'isSkuRequired';
  public static readonly IS_VALID = 'is_valid';
  public static readonly IS_VARIABLE_QUANTITY_REQUIRED_FORM = 'isVariableQuantityRequired';
  public static readonly IS_WEP_WAREHOUSE = 'isWepWarehouse';
  public static readonly KEYUP = 'keyup';
  public static readonly KIT_COMPONENT_NOT_FOUND = 'KIT_COMPONENT_NOT_FOUND';
  public static readonly KIT_PREFIX = 'Kit:';
  public static readonly KITTING_ALLOCATION_ROUTE_ID = 3;
  public static readonly KITTING_ALLOCATION_ROUTE_NAME = 'Kitting';
  public static readonly KITTING_MODULE_NAME = 'kitting';
  public static readonly KITTING_ORDER_ADD_DETAILS_STEP = 1;
  public static readonly KITTING_ORDER_DETAILS_FOUND = 'KITTING_ORDER_DETAILS_FOUND';
  public static readonly KITTING_ORDER_MODAL_WIDTH = 500;
  public static readonly KITTING_ORDERS_NOT_FOUND = 'KITTING_ORDERS_NOT_FOUND';
  public static readonly LABEL_CONFIGURATION_SIZE_FILE = 3000000;
  public static readonly LABEL_PARAM_KEY_WORD = '%t';
  public static readonly LAST_CHANGE = 'lastChange';
  public static readonly LATITUDE = 19.4326077;
  public static readonly LBLTEXT = 'lblText';
  public static readonly LESS_ONE = -1;
  public static readonly LIST_OPTIONS_STYLE = { 'width': '100%' };
  public static readonly LOAD_TYPE = 'loadType';
  public static readonly LOCALSTORAGE_MODULE = 'labels';
  public static readonly LOCATION = 'location';
  public static readonly LOCATION_CANNOT_BE_UPDATED = 'LOCATION_CANNOT_BE_UPDATED';
  public static readonly LOCATION_COUNT_PROGRESS = 'locationCountProgress';
  public static readonly LOCATION_DOES_NOT_ALLOW_MIXED_LOT = 'LOCATION_DOES_NOT_ALLOW_MIXED_LOT';
  public static readonly LOCATION_DOES_NOT_ALLOW_MIXED_LPN = 'LOCATION_DOES_NOT_ALLOW_MIXED_LPN';
  public static readonly LOCATION_EQUIPMENT_PC = 1;
  public static readonly LOCATION_HAS_PENDING_TASKS = 'LOCATION_HAS_PENDING_TASKS';
  public static readonly LOCATION_ID = 'locationId';
  public static readonly LOCATION_ID_CRITERIA = 'location_id';
  public static readonly LOCATION_IN_COUNT = 'LOCATION_IN_COUNT';
  public static readonly LOCATION_IS_NOT_AVAILABLE = 'LOCATION_IS_NOT_AVAILABLE';
  public static readonly LOCATION_IS_ON_COUNTING = 'LOCATION_IS_ON_COUNTING';
  public static readonly LOCATION_IS_ON_ERROR = 'LOCATION_IS_ON_ERROR';
  public static readonly LOCATION_NOT_FOUND = 'LOCATION_NOT_FOUND';
  public static readonly LOCATION_RULES_ERROR = 'LOCATION_RULES_ERROR';
  public static readonly LOCATION_SEARCH = 1;
  public static readonly LOCATION_SKU = 'locationOrSku';
  public static readonly LOCATION_SMALL_FONT_SIZE = 0.9;
  public static readonly LOCATION_STATUS = 'locationStatus';
  public static readonly LOCATION_TIMEOUT = 100;
  public static readonly LOCATION_TYPE_IS_NOT_RECEIVING_STAGE = 'LOCATION_TYPE_IS_NOT_RECEIVING_STAGE';
  public static readonly LOCATIONS_NOT_FOUND = 'LOCATIONS_NOT_FOUND';
  public static readonly LOCATIONS_TASK = 'locationsTask';
  public static readonly LOGIN_URL = '/login';
  public static readonly LONGITUDE = -99.133207;
  public static readonly LOT = 'lot';
  public static readonly LOT_DAYS_INTERVAL = 'lotDaysInterval';
  public static readonly LOT_IS_NOT_REQUIRED = 'LOT_IS_NOT_REQUIRED';
  public static readonly LOT_IS_REQUIRED = 'LOT_IS_REQUIRED';
  public static readonly LOT_NOT_FOUND = 'LOT_NOT_FOUND';
  public static readonly LPN = 'lpn';
  public static readonly LPN_BY_RECEIPT_ASN_HAS_BEEN_RECEIPTED = 'LPN_BY_RECEIPT_ASN_HAS_BEEN_RECEIPTED';
  public static readonly LPN_CANNOT_BE_CREATED = 'LPN_CANNOT_BE_CREATED';
  public static readonly LPN_DESTINATION = 'lpnDestination';
  public static readonly LPN_DESTINY = 'lpnDestiny';
  public static readonly LPN_DOES_NOT_BELONG_TO_SHIPMENT = 'LPN_DOES_NOT_BELONG_TO_SHIPMENT';
  public static readonly LPN_DOES_NOT_BELONG_TO_SHIPMENT_STOP = 'LPN_DOES_NOT_BELONG_TO_SHIPMENT_STOP';
  public static readonly LPN_HAS_ASSOCIATED_PALLET_TASK = 'LPN_HAS_ASSOCIATED_PALLET_TASK';
  public static readonly LPN_HAS_TASK = 'LPN_HAS_TASK';
  public static readonly LPN_IS_NOT_AVAILABLE = 'LPN_IS_NOT_AVAILABLE';
  public static readonly LPN_NOT_FOUND = 'LPN_NOT_FOUND';
  public static readonly LPN_NOT_FOUND_IN_COUNT = 'LPN_NOT_FOUND_IN_COUNT';
  public static readonly LPN_NOT_FOUND_PACKAGE_LOCATION = 'LPN_NOT_FOUND_PACKAGE_LOCATION';
  public static readonly LPN_OF_TASK_HAS_COMMITED_QUANTITY = 'LPN_OF_TASK_HAS_COMMITED_QUANTITY';
  public static readonly LPN_ORIGIN = 'lpnOrigin';
  public static readonly LPN_SEARCH = 0;
  public static readonly LPN_STRING = 'lpnString';
  public static readonly LPN_TRANSFER = 'LpnTransfer';
  public static readonly LPN_UNAVAILABLE = 'LPN_UNAVAILABLE';
  public static readonly MAIN_ELEMENT = 'main';
  public static readonly MANUAL_TMS_INTEGRATOR = 'manual_tms_integrator';
  public static readonly MAX_DECIMAL_VALUE_DB = 9999999;
  public static readonly MAX_LOCATION_CAPACITY = 99_999_999.99;
  public static readonly MAX_LOCATION_WEIGHT = 99_999_999;
  public static readonly MAX_FILE_IMPORT_SIZE = 250000;
  public static readonly MAX_FILE_SIZE = 25000000;
  public static readonly MAX_INT_VALUE = 2147483647;
  public static readonly MAX_LENGHT_QUANTITY_INPUT_FOR_DECIMAL_MASK = 13;
  public static readonly MAX_LENGTH_INVENTORY_DETAIL = 5;
  public static readonly MAX_LENGTH_PRODUCT_SHORT_DESCRIPTION = 50;
  public static readonly MAX_LENGTH_SAT_KEY = 50;
  public static readonly MAX_LENGTH_SERIAL = 100;
  public static readonly MAX_LENGTH_SHORT_DESCRIPTION = 50;
  public static readonly MAX_LENGTH_TEMPLATE_NAME = 50;
  public static readonly MAX_LIST_TO_PROCESS = 5;
  public static readonly MAX_NUMBER_OF_PALLETS = 10;
  public static readonly MAX_OVER_RECEIPT_PERCENTAGE = 200;
  public static readonly MAX_PRIORITY = 99999;
  public static readonly MAX_QUANTITY_PER_PALLET = 'max_quantity_per_pallet';
  public static readonly MAX_TEMPERATURE = 999;
  public static readonly MAXIMUM_VOLUME = 99999999999999.9999;
  public static readonly MEASURE_BY_CASE = 'measure_by_case';
  public static readonly MEASURE_STEP = 0.0001;
  public static readonly MIN = 0;
  public static readonly MIN_LENGTH = 3;
  public static readonly MIN_LENGTH_PARAM = 'product_autocomplete_min_input_length';
  public static readonly MIN_NUMBER_OF_CONCURRENT_EQUIPMENTS = 1;
  public static readonly MIN_NUMBER_OF_DOORS = 1;
  public static readonly MIN_NUMBER_OF_PALLETS = 1;
  public static readonly MIN_PASSWORD_LENGTH = 4;
  public static readonly MIN_PRIORITY = 1;
  public static readonly MIN_QUANTITY = 1;
  public static readonly MIN_QUANTITY_DECIMAL = 0.01;
  public static readonly MIN_SIZE = 1;
  public static readonly MIN_TEMPERATURE = -100;
  public static readonly MIN_VOLUME = 0;
  public static readonly MINIMUM_ORDER = 0;
  public static readonly MINIMUM_PASSWORD_LENGTH = 'minimum_password_length';
  public static readonly MINUS_ONE = -1;
  public static readonly MINUTES = 'minutes';
  public static readonly MOBILE_PICKING_INTEGRATOR = 'wep_mobile_picking_integrator';
  public static readonly MOBILE_PRIORITY = 'mobilePriority';
  public static readonly MODULE_PLANNING = 7;
  public static readonly MOVEMENT_BETWEEN_AREA_NOT_FOUND = 'MOVEMENT_BETWEEN_AREA_NOT_FOUND';
  public static readonly MOVEMENT_HISTORY_CANNOT_BE_FOUND = 'MOVEMENT_HISTORY_CANNOT_BE_FOUND';
  public static readonly MOVEMENT_HISTORY_MODULE = 3;
  public static readonly MOVEMENT_HISTORY_MODULE_NAME = 'max_days_range';
  public static readonly MOVEMENT_PATH_ALREADY_EXISTS = 'MOVEMENT_PATH_ALREADY_EXISTS';
  public static readonly MOVEMENT_PATH_CANNOT_BE_FOUND = 'MOVEMENT_PATH_CANNOT_BE_FOUND';
  public static readonly MOVEMENT_PATH_DETAIL_NOT_FOUND = 'MOVEMENT_PATH_DETAIL_NOT_FOUND';
  public static readonly MOVEMENT_PATH_WITH_SHORTS = 'MOVEMENT_PATH_WITH_SHORTS';
  public static readonly MOVEMENT_PATH_WITH_TASKS = 'MOVEMENT_PATH_WITH_TASKS';
  public static readonly MOVEMENT_PICKING_PIECE_SCANNED_CANNOT_BE_FOUND = 'MOVEMENT_PICKING_PIECE_SCANNED_CANNOT_BE_FOUND';
  public static readonly MOVEMENT_RECEIPT_PIECE_DETAIL_CANNOT_BE_FOUND = 'MOVEMENT_RECEIPT_PIECE_DETAIL_CANNOT_BE_FOUND';
  public static readonly MOVEMENT_TYPE = 'movementType';
  public static readonly MULTIPLY = 'multiply';
  public static readonly NAME = 'name';
  public static readonly NAVIGATE_ROUTE = 2000;
  public static readonly NEGATIVE = '-';
  public static readonly NEGATIVE_ONE = -1;
  public static readonly NEW_PICKING_ORDER = 'newPickingOrder';
  public static readonly NEW_REGEX_VALIDATOR = /^[a-zA-Z0-9_-]+$/;
  public static readonly NEXT_TASK = 20000;
  public static readonly NICKNAME = 'nickname';
  public static readonly NICKNAME_PATTERN = '[a-zA-Z0-9_]+';
  public static readonly NINE = 9;
  public static readonly NO_ANSWER = 'n';
  public static readonly NO_AVAILABLE_INVENTORY_ATTRIBUTES_IN_LOCATION = 'NO_AVAILABLE_INVENTORY_ATTRIBUTES_IN_LOCATION';
  public static readonly NO_AVAILABLE_INVENTORY_IN_LOCATION = 'NO_AVAILABLE_INVENTORY_IN_LOCATION';
  public static readonly NO_DETAILS_IN_HEADER = 'NO_DETAILS_IN_HEADER';
  public static readonly NO_INVENTORY_IN_LPN = 'NO_INVENTORY_IN_LPN';
  public static readonly NO_INVENTORY_STATUS = 'inventoryStatusNotAssigned';
  public static readonly NOT_APPLY = 'N/A';
  public static readonly NOT_FOUND_PRODUCT = 'NOT_FOUND_PRODUCT';
  public static readonly NUMBER_PATTERN = '[0-9]+';
  public static readonly OFFLINE_ERROR = 'OFFLINE_ERROR';
  public static readonly ONCE_CALLED = 1;
  public static readonly ONE = 1;
  public static readonly ONE_CAPITAL_REGEXP = /[A-Z]{1,}/;
  public static readonly ONE_HUNDRED_FIFTY = 150;
  public static readonly ONE_MILLION = 1000000;
  public static readonly ONE_NUMBER_REGEXP = /[0-9]{1,}/;
  public static readonly ONE_SPECIAL_CHARACTER_REGEXP = /[!@#\$%\^\'\"\&\\*\)\(+=._-]{1,}/;
  public static readonly ONLY_NUMBERS_REGEXP = /^[0-9]+$/;
  public static readonly OPEN_STATUS = 'Open';
  public static readonly OPTION_SELECT = 'option_select';
  public static readonly OPTION1 = '1';
  public static readonly OPTION2 = '2';
  public static readonly OPTION3 = '3';
  public static readonly OPTION4 = '4';
  public static readonly OPTIONS = 'options';
  public static readonly OR = 'or';
  public static readonly ORDER = 'order';
  public static readonly ORDER_BY_TITLE = 'title';
  public static readonly ORDER_COMPLETED = 'ORDER_COMPLETED';
  public static readonly ORDER_HAS_INVALID_STAGES = 'ORDER_HAS_INVALID_STAGES';
  public static readonly ORDER_ID = 2;
  public static readonly ORDER_IS_PARTIAL = 'ORDER_IS_PARTIAL';
  public static readonly ORDER_NOT_FOUND = 'ORDER_NOT_FOUND';
  public static readonly ORDER_NUMBER = 'orderNumber';
  public static readonly ORDER_STATUS_IS_PLANNED = 'ORDER_STATUS_IS_PLANNED';
  public static readonly ORDER_TYPE = 'orderType';
  public static readonly ORDERED_PIECES = 'ordered_pieces';
  public static readonly ORDERED_PIECES_ICON_PATH = 'assets/icons/packstation/ordered_pieces.svg';
  public static readonly ORDERED_QUANTITY = 'orderedQuantity';
  public static readonly ORIGIN = 'origin';
  public static readonly ORIGIN_LOCATION = 'originLocation';
  public static readonly ORIGIN_LOCATION_ID = 'originLocationId';
  public static readonly ORIGIN_LPN = 'originLpn';
  public static readonly ORIGIN_PRODUCT = 'originProduct';
  public static readonly ORIGINLOCATION = 'originLocation';
  public static readonly OUT_OF_DATE_INVENTORY = 'OUT_OF_DATE_INVENTORY';
  public static readonly OUTLINE_STYLE = 'outline';
  public static readonly PACK_STATION = 'Packstation';
  public static readonly PACK_STATION_AREA_TYPE = '00000000000000101';
  public static readonly PACK_STATION_MODULE_NAME = 'Empaque';
  public static readonly PACKAGE_SHIPMENT_NOT_FOUND = 'PACKAGE_SHIPMENT_NOT_FOUND';
  public static readonly PACKING_LIST_TYPE = 'Packing list';
  public static readonly PALLET_MODULE = 2;
  public static readonly PALLETS = 'pallets';
  public static readonly PARAM_NOT_FOUND = 'PARAM_NOT_FOUND';
  public static readonly PARAM_ONE_VALUE = '1';
  public static readonly PARAM_ZERO_VALUE = '0';
  public static readonly PARAMETER = 'parameter';
  public static readonly PARTIAL = 'Partial';
  public static readonly PARTIAL_COLUMNS = 'col-md-8';
  public static readonly PARTIAL_PICKING_PARAMETER = 'ALLOW_PARTIAL_PICKING';
  public static readonly PARTIAL_PRODUCT_STYLE = 'partialProduct';
  public static readonly PARTIAL_SHIPMENT = 'partial_shipment';
  public static readonly PARTIAL_STATUS = 'partialProductStatus';
  public static readonly PASSWORD = 'password';
  public static readonly PASSWORD_ADMIN_REGEXP = '^[0-9a-zA-Z!@#\$%\^\'\"\&\\*\)\(+=._-]{%min%,}$';
  public static readonly PASSWORD_LENGTH_REGEXP = '^.{%min%,}$';
  public static readonly PASSWORD_OPERATOR_REGEXP = '^[0-9]{%min%,}$';
  public static readonly PATTERN_STRING_SPACES = '[\\s]*[\\S][\\s\\S]*';
  public static readonly PDF = '.pdf';
  public static readonly PENDING_STATUS = 'pendingTaskStatus';
  public static readonly PENDING_TASK_STYLE = 'pendingTask';
  public static readonly PENDING_WORK_NOT_FOUND = 'PENDING_WORK_NOT_FOUND';
  public static readonly PENDING_WORK_TIME = 4000;
  public static readonly PERCENT = '%';
  public static readonly PERCENT_SIGN = '%';
  public static readonly PERCENTAGE = 'percentage';
  public static readonly PICK_TO_TRUCK = 'pick_to_truck';
  public static readonly PICKED_PIECES = 'picked_pieces';
  public static readonly PICKED_PIECES_ICON_PATH = 'assets/icons/packstation/picked_pieces.svg';
  public static readonly PICKED_QUANTITY_SURPASSES_FOUND_IMPORT_NUMBER_QUANTITY = 'PICKED_QUANTITY_SURPASSES_FOUND_IMPORT_NUMBER_QUANTITY';
  public static readonly PICKING_SKU_AUTOCOMPLETE_PARAM = 'picking_sku_autocomplete_confirm';
  public static readonly PICKING_ALLOCATION_ROUTE_ID = 1;
  public static readonly PICKING_CARROUSEL = 'pickingCarrousel';
  public static readonly PICKING_LIST = 'PickingList';
  public static readonly PICKING_LIST_ACCOUNTS = 'pickingListRelatedAccounts';
  public static readonly PICKING_LIST_COMPONENT = 'PickingListComponent';
  public static readonly PICKING_LIST_CONFIGURATION_NOT_FOUND = 'PICKING_LIST_CONFIGURATION_NOT_FOUND';
  public static readonly PICKING_LIST_CONFIGURATION_TYPES = 'pickingListConfigurationTypes';
  public static readonly PICKING_LIST_MODULE = 2;
  public static readonly PICKING_LIST_ROUTE = 'picking-list';
  public static readonly PICKING_LIST_TYPE = 'PickingList';
  public static readonly PICKING_LIST_WORKING_ORDER_TYPE = 'PickingListWorkingOrder';
  public static readonly PICKING_LPN_PARAM = 'lpn_picking';
  public static readonly PICKING_LPN_PARAMETER = 'picking_lpn';
  public static readonly PICKING_MODULE = 2;
  public static readonly PICKING_MODULE_STRING = 'picking';
  public static readonly PICKING_ORDER_CONS = 'Picking';
  public static readonly PICKING_PAGINATOR_SEARCH = 10;
  public static readonly PICKING_PALLET = 'PickingPallet';
  public static readonly PICKING_PALLET_ROUTE = 'picking-pallet';
  public static readonly PICKING_PALLET_TYPE = 'PickingPallet';
  public static readonly PICKING_PALLET_WORKING_ORDER_TYPE = 'PickingPalletWorkingOrder';
  public static readonly PICKING_STAGE_AREA_TYPE = '00010000000000000';
  public static readonly PICKING_TYPE = 2;
  public static readonly PICKING_WIHOUT_ORDER = 'PickingWithoutOrder';
  public static readonly PICKING_WIHOUT_ORDER_ACTIVITY = 'Picking Without Shipment Order';
  public static readonly PICKING_WIHOUT_ORDER_CONS = 'PickingWithoutShipmentOrder';
  public static readonly PIECES = 'Pzas';
  public static readonly PIECES_TO_PACK = 'pieces_to_pack';
  public static readonly PIECES_TO_PACK_ICON_PATH = 'assets/icons/packstation/pieces_to_pack.svg';
  public static readonly PING_TIMEOUT_REASON = 'ping timeout';
  public static readonly PIPE_DATE_FORMAT = 'yyyy-MM-dd';
  public static readonly PIPE_TIME_ZONE = 'GMT+3';
  public static readonly PLANNING = 'Planning';
  public static readonly PLANNING_BY_OPS = 'planningByOPS';
  public static readonly PLANNING_BY_WEP = 'planningByWEP';
  public static readonly PLANNING_MODULE = 7;
  public static readonly PLATE = 'plate';
  public static readonly PLATES = 'plates';
  public static readonly POLICY = 'policy';
  public static readonly PRDOUCTS_TASK = 'productsTask';
  public static readonly PREFIX = 'CTE';
  public static readonly PREPARATION_STAGE = 'PreparationStage';
  public static readonly PREVIOUS = 'previous';
  public static readonly PRIORITY = 'priority';
  public static readonly PRIORITY_DECREASE = -1;
  public static readonly PRIORITY_INCREASE = 1;
  public static readonly PRODUCT = 'product';
  public static readonly PRODUCT_AUTOCOMPLETE_ID = 'product-autocomplete';
  public static readonly PRODUCT_ID = 'productId';
  public static readonly PRODUCT_ID_CRITERIA = 'product_id';
  public static readonly PRODUCT_NOT_FOUND = 'PRODUCT_NOT_FOUND';
  public static readonly PRODUCTS_NOT_FOUND = 'PRODUCTS_NOT_FOUND';
  public static readonly PROGRESS_DETAIL = 'progress-receipt-order/view-detail/';
  public static readonly PROGRESS_LPN_DETAIL = 'progress-receipt-order/view-detail-lpn/';
  public static readonly PROGRESS_STEP = 'progressStep';
  public static readonly PROGRESSION = 'progression';
  public static readonly PROGRESSION_DETAIL = 'progressionDetail';
  public static readonly PUT_AWAY = 'PutAway';
  public static readonly PUT_AWAY_MODULE = 4;
  public static readonly PUT_AWAY_POLICIES_NOT_FOUND = 'PUT_AWAY_POLICIES_NOT_FOUND';
  public static readonly PUT_AWAY_ROUTE = '/put-away-policy';
  public static readonly PUT_AWAY_SEQUENCE = 'putAwaySequence';
  public static readonly PUT_AWAY_SUGGESTED_LOCATION_NOT_FOUND = 'PUT_AWAY_SUGGESTED_LOCATION_NOT_FOUND';
  public static readonly QUANTITIY_RECEIVED = 'quantityReceived';
  public static readonly QUANTITY = 'quantity';
  public static readonly QUANTITY_ORDER = 'quantityOrder';
  public static readonly QUANTITY_PER_CASE = 'quantity_per_case';
  public static readonly QUANTITY_PHYSICAL_FIRST_COUNT = 'quantityPhysicalFirstCount';
  public static readonly QUANTITY_PHYSICAL_SECOND_COUNT = 'quantityPhysicalSecondCount';
  public static readonly QUANTITY_PHYSICAL_THIRD_COUNT = 'quantityPhysicalThirdCount';
  public static readonly QUANTITY_ZERO = '0';
  public static readonly RADIX_DEFAULT = 10;
  public static readonly REALLOCATE = 'Reallocate';
  public static readonly RELOCATION_REPORTER = 'relocation_reporter';
  public static readonly REALLOCATE_LPN_TO_SAME_LPN = 'REALLOCATE_LPN_TO_SAME_LPN';
  public static readonly REALLOCATE_MODULE = 6;
  public static readonly REASON = 'reason';
  public static readonly REASON_DEFAULT = 2;
  public static readonly REASON_HAS_DEPENDENCIES = 'REASON_HAS_DEPENDENCIES';
  public static readonly REASON_NOT_FOUND = 'REASON_NOT_FOUND';
  public static readonly REASON_TYPE = 'reasonType';
  public static readonly RECEIPT_APPOINTMENT = 'ReceiptAppointment';
  public static readonly RECEIPT_APPOINTMENT_AGENDA = 'receipt-appointment/add';
  public static readonly RECEIPT_APPOINTMENT_CALENDAR = 'receipt/receipt-appointment-calendar';
  public static readonly RECEIPT_APPOINTMENT_CANNOT_BE_FOUND = 'RECEIPT_APPOINTMENT_CANNOT_BE_FOUND';
  public static readonly RECEIPT_APPOINTMENT_DATE_FORM_VALUE = 'appointmentDate';
  public static readonly RECEIPT_APPOINTMENT_PATH = '/receipt-appointment';
  public static readonly RECEIPT_APPOINTMENT_REPORTS_NOT_FOUND = 'RECEIPT_APPOINTMENT_REPORTS_NOT_FOUND';
  public static readonly RECEIPT_APPOINTMENT_ROUTER = 'receipt-rf/appointment';
  public static readonly RECEIPT_APPOINTMENT_SERVICE = 'appointment';
  public static readonly RECEIPT_ASN = 'ReceiptASN';
  public static readonly RECEIPT_DETAIL_ASN_CANNOT_BE_FOUND = 'RECEIPT_DETAIL_ASN_CANNOT_BE_FOUND';
  public static readonly RECEIPT_DETAIL_ASN_LPN_CANT_BE_ZERO = 'RECEIPT_DETAIL_ASN_LPN_CANT_BE_ZERO';
  public static readonly RECEIPT_DETAIL_ASN_LPNS_CANT_BE_ZERO = 'RECEIPT_DETAIL_ASN_LPNS_CANT_BE_ZERO';
  public static readonly RECEIPT_DETAIL_CANNOT_BE_FOUND = 'RECEIPT_DETAIL_CANNOT_BE_FOUND';
  public static readonly RECEIPT_DETAIL_WITHOUT_PENDING_QUANTITY = 'RECEIPT_DETAIL_WITHOUT_PENDING_QUANTITY';
  public static readonly RECEIPT_EDIT = 'receipt-policy/edit/';
  public static readonly RECEIPT_FOLIO = 'ReceiptTruckArrival';
  public static readonly RECEIPT_FOLIO_PATH = '/truck-arrival';
  public static readonly RECEIPT_FOLIO_ROUTER = 'receipt-rf/folio';
  public static readonly RECEIPT_FOLIO_SERVICE = 'truck-arrival';
  public static readonly RECEIPT_MODULE = 1;
  public static readonly RECEIPT_ORDER = '/receipt/receipt-order';
  public static readonly RECEIPT_ORDER_ALREADY_EXISTS = 'RECEIPT_ORDER_ALREADY_EXISTS';
  public static readonly RECEIPT_ORDER_CANNOT_BE_FOUND = 'RECEIPT_ORDER_CANNOT_BE_FOUND';
  public static readonly RECEIPT_ORDER_DETAILS = 'receiptOderDetails';
  public static readonly RECEIPT_ORDER_MODULE = 10;
  public static readonly RECEIPT_ORDER_PATH = '/receipt-order';
  public static readonly RECEIPT_ORDER_WITH_ASN = 'receipt_order_with_asn';
  public static readonly RECEIPT_ORDERED = 'ReceiptOrdered';
  public static readonly RECEIPT_ORDERED_MODULE = 10;
  public static readonly RECEIPT_ORDERED_SERVICE = 'order';
  public static readonly RECEIPT_PER_PIECE_WITHOUT_ORDER = 'Receipt per piece without order';
  public static readonly RECEIPT_PIECE_DATA = 'data';
  public static readonly RECEIPT_POLICIES_NOT_FOUND = 'RECEIPT_POLICIES_NOT_FOUND';
  public static readonly RECEIPT_POLICY = 'receipt-policy';
  public static readonly RECEIPT_POLICY_ADD = 'receipt-policy/add';
  public static readonly RECEIPT_POLICY_EDIT_VALUE = 'receiptTypeOrder';
  public static readonly RECEIPT_POLICY_NOT_FOUND = 'RECEIPT_POLICY_NOT_FOUND';
  public static readonly RECEIPT_PROCESS_ROUTER = 'receipt-rf';
  public static readonly RECEIPT_PROGRESS = 'progress-receipt-order/';
  public static readonly RECEIPT_PROGRESS_FOLIO = 'receiptFolio';
  public static readonly RECEIPT_REJECTION = 'RejectionReceipt';
  public static readonly RECEIPT_REPORT = 'Generar acuse de recibo';
  public static readonly RECEIPT_SKU_AUTOCOMPLETE_PARAM = 'receipt_sku_autocomplete_confirm';
  public static readonly RECEIPT_STAGE_AREA_TYPE = '01000000000000000';
  public static readonly RECEIPT_TIME_OUT = 1000;
  public static readonly RECEIPT_TRUCK_ARRIVAL = 'ReceiptTruckArrival';
  public static readonly RECEIPT_TYPE = 'progressOption';
  public static readonly RECEIPT_TYPE_ORDER = 'receiptTypeOrder';
  public static readonly RECEIPT_WITHOUT_ORDER = 'ReceiptWithoutOrder';
  public static readonly RECEIPT_WITHOUT_ORDER_BY_ASN = 'receipt_without_order_by_asn';
  public static readonly RECEIPT_WITHOUT_ORDER_SERVICE = 'without-order';
  public static readonly RECEIVING_SUPPLIER = 'ReceivingSupplier';
  public static readonly REFRESH_TIME = 60000;
  public static readonly REFRESH_TOKEN = 'refresh';
  public static readonly REGEX_MASK_TO_DATE_FORMAT = /^\d{4}-\d{2}-\d{2}$/;
  public static readonly REGEX_ONLY_ACCEPT_DASH_AND_CHARACTERS = /^[a-zA-Z_\-]+$/;
  public static readonly REGEX_ONLY_ACCEPT_DASH_CHARACTERS_AND_DOTS = /^[a-zA-Z_.\-]+$/;
  public static readonly REGEX_ONLY_ACCEPT_DASH_CHARACTERS_DOTS_AND_NUMBERS = /^[a-zA-Z0-9_.\-]+$/;
  public static readonly REGEX_VALIDATOR = /[!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?]+/;
  public static readonly REJECTION_RECEIPT = 'RejectionReceipt';
  public static readonly RELATED_ACCOUNTS = 'RelatedAccounts';
  public static readonly RELEASE_PACKSTATION_ACTIVITY = 'Release Packstation Location';
  public static readonly RELEASE_SHORT_ALLOCATION = 'Release Short Allocation';
  public static readonly RELEASE_SHORT_ROLE = 'Liberar Cortos';
  public static readonly RELEASE_TASK = 'Release Task';
  public static readonly REMOVE_HOLD_CAPACITY_PARAMETER = 'remove_hold_capacity';
  public static readonly RENDER_BREAKPOINT = 500;
  public static readonly REORDER_TIME = 10000;
  public static readonly REPLENISHMENT_ALLOCATION_ROUTE_ID = 2;
  public static readonly REPLENISHMENT_ALLOCATION_ROUTE_NAME = 'Reabasto';
  public static readonly REPLENISHMENT_CONFIGURATION_CANNOT_BE_FOUND = 'REPLENISHMENT_CONFIGURATION_CANNOT_BE_FOUND';
  public static readonly REPLENISHMENT_LEVEL_TASK = 'LevelReplenishment';
  public static readonly REPLENISHMENT_LOCATION_PARAMETER = 'replenishment_location';
  public static readonly REPLENISHMENT_MODULE = 'replenishment';
  public static readonly REPLENISHMENT_MODULE_ID = 9;
  public static readonly REPLENISHMENT_PRODUCT_NOT_FOUND = 'REPLENISHMENT_PRODUCT_NOT_FOUND';
  public static readonly REPLENISHMENT_SKU_PARAMETER = 'replenishment_SKU';
  public static readonly REPLENISHMENT_TOP_OFF_TASK = 'TopOffReplenishment';
  public static readonly REPLENISHMENT_TYPE = 'Replenishment';
  public static readonly REPORT_TYPE_AUDIT = 'Audit';
  public static readonly REPORT_TYPE_CASE_ID = 'CaseId';
  public static readonly REPORT_TYPE_LOCATION = 'Location';
  public static readonly REPORT_TYPE_LPN = 'Lpn';
  public static readonly REPORT_TYPE_SKU = 'Sku';
  public static readonly REQUIRED_EXPIRATION = 'required_expiration';
  public static readonly REQUIRED_INVOICE_NUMBER = 'required_invoice_number';
  public static readonly REQUIRED_REASON_RECEIPT_APPOINTMENT = 'required_reason_receipt_appointment';
  public static readonly REQUIRED_REASON_RECEIPT_FOLIO = 'required_reason_receipt_folio';
  public static readonly REQUIRED_REASON_RECEIPT_ORDER = 'required_reason_receipt_order';
  public static readonly REQUIRED_REASON_RECEIPT_WITHOUT_ORDER = 'required_reason_receipt_without_order';
  public static readonly RESP_NO = 'n';
  public static readonly RESP_SI = 's';
  public static readonly RESPECT_STOPS = 'respectStops';
  public static readonly RESTARTINDEX = -1;
  public static readonly ROLE_ADD_ROUTE = 'role/add';
  public static readonly ROLE_EDIT_ROUTE = 'role/edit/';
  public static readonly ROLE_MANAGEMENT_ROUTE = 'role/management';
  public static readonly ROLE_NAME_PATTERN = '[a-zA-Záéíóúñü0-9]+[a-zA-Záéíóúñü0-9 ]*';
  public static readonly RRS_TIMEOUT = 10000;
  public static readonly SCANNER_ICON = 'barcode';
  public static readonly SCANNER_ICON_URL = '../../../../../../assets/icons/scan-barcode.svg';
  public static readonly SEARCH_DATE_RANGE = 5;
  public static readonly SECOND_COUNT = 2;
  public static readonly SECOND_STEP = 1;
  public static readonly SECONDS = 'seconds';
  public static readonly SECURE_NUMBER = 'secureNumber';
  public static readonly SELECTED_ACCOUNT = 'SelectedAccount';
  public static readonly SEMICOLON = ';';
  public static readonly SEND_ORDER_TMS_INTEGRATOR_PARAMETER = 'send_order_tms_integrator';
  public static readonly SERIAL = 'serial';
  public static readonly SERVICE_URL = 'serviceURL';
  public static readonly SET_DEFAULT_TIME = 1000;
  public static readonly SEVEN = 7;
  public static readonly SHIPMENT = 'Shipment';
  public static readonly SHIPMENT_ADDRESS = 'shipmentAddress';
  public static readonly SHIPMENT_AREA = '00100000000000000';
  public static readonly SHIPMENT_AVAILABLE = 'Available';
  public static readonly SHIPMENT_IN_PROGRESS = 'InProgress';
  public static readonly SHIPMENT_MODULE = 2;
  public static readonly SHIPMENT_MODULE_HIGHER = 17;
  public static readonly SHIPMENT_MODULE_STRING = 'Embarque';
  public static readonly SHIPMENT_ORDER = 'shipmentOrder';
  public static readonly SHIPMENT_ORDER_LOAD = 'shipmentOrderLoad';
  public static readonly SHIPMENT_ORDER_LOAD_ROUTE = '/shipment-order-load';
  public static readonly SHIPMENT_ORDER_NOT_FOUND = 'SHIPMENT_ORDER_NOT_FOUND';
  public static readonly SHIPMENT_ORDER_STATUS = 'shipmentOrderStatus';
  public static readonly SHIPMENT_ORDER_STATUS_COMPLETED = 7;
  public static readonly SHIPMENT_ORDER_STATUS_PICKING_ON = 4;
  public static readonly SHIPMENT_ORDER_STATUS_PLANNED = 6;
  public static readonly SHIPMENT_ORDER_STATUS_TRANSLATED = 'shipmentOrderStatusTranslated';
  public static readonly SHIPMENT_ORDER_TEMPLATE_MODULE_ID = 1;
  public static readonly SHIPMENT_PACKERS_AREA_TYPE = '00010000000000000';
  public static readonly SHIPMENT_SECURE_NUMBER_MODAL_WIDTH = 500;
  public static readonly SHIPMENT_SECURE_NUMBER_PARAM = 'shipment_secure_number';
  public static readonly SHIPMENT_SENT_PARTIAL = 'SentPartial';
  public static readonly SHIPMENT_STOP_IS_NOT_THE_NEXT = 'SHIPMENT_STOP_IS_NOT_THE_NEXT';
  public static readonly SHIPMENT_STOP_NOT_FOUND = 'SHIPMENT_STOP_NOT_FOUND';
  public static readonly SHIPMENT_TEMPERATURE = 'shipment_temperature';
  public static readonly SHIPMENT_TIMEOUT = 1000;
  public static readonly SHIPPER = 'shipper';
  public static readonly SHIPPING_ADRESS = 'shippingAddress';
  public static readonly SHORT_ALLOCATION_NOT_FOUND = 'SHORT_ALLOCATION_NOT_FOUND';
  public static readonly SHORT_ALOCATION_STYLE = 'partialProduct';
  public static readonly SHOW_APPOINTMENT_GRID = 'receipt-appointment';
  public static readonly SHOW_LPN_CONTAINTS = 'show_lpn_containts';
  public static readonly SIMPLE_PICKING_CONS = 'Picking';
  public static readonly SIMPLE_PICKING_TYPE = 'SimplePicking';
  public static readonly SINGLE_PRODUCT_LPN_PARAMETER = 'single_product_lpn';
  public static readonly SIX = 6;
  public static readonly SIX_COLUMNS = 6;
  public static readonly SIXTY = 60;
  public static readonly SIXTY_SECONDS = 60;
  public static readonly SIZE_ARRAY = 1;
  public static readonly SIZE_BAR_LARGE = 'large';
  public static readonly SIZE_BAR_MEDIUM = 'medium';
  public static readonly SKU = 'sku';
  public static readonly SKU_STEP = 'skuStep';
  public static readonly SKUS_SCANNED = 'skus_scanned';
  public static readonly SKUS_SCANNED_ICON_PATH = 'assets/icons/packstation/skus_scanned.svg';
  public static readonly SMALL = 'small';
  public static readonly SMALL_SIZE = 'sm';
  public static readonly SOME_GUIDES_WITH_ERROR = 'SOME_GUIDES_WITH_ERROR';
  public static readonly SORTING_ATTRIBUTE = 'priority';
  public static readonly SORTING_ORDER = 'asc';
  public static readonly SORTING_ORDER_DESC = 'desc';
  public static readonly STAGE = 'stage';
  public static readonly STAGE_LOCATION = 'stageLocation';
  public static readonly START_SUM_VALUE = 0;
  public static readonly STATUS = 'status';
  public static readonly STATUS_CHANGE_IN_PICKING = 'status_change_in_picking';
  public static readonly STATUS_TRANSLATED = 'statusTranslated';
  public static readonly STEP = 1;
  public static readonly STEP_NUMBER_FIELD = 1;
  public static readonly STEP_TIME = 800;
  public static readonly STOP = 'stop';
  public static readonly STOP_NAME = 'Parada';
  public static readonly STORE_ALREADY_EXISTS = 'STORE_ALREADY_EXISTS';
  public static readonly STORE_ALREADY_RELATED_TO_AN_API = 'STORE_ALREADY_RELATED_TO_AN_API';
  public static readonly STORE_RELATED_TO_SHIPMENT_ORDER = 'STORE_RELATED_TO_SHIPMENT_ORDER';
  public static readonly STRING_PATTERN = '[a-zA-Z0-9._%+-]+';
  public static readonly SUGGESTED_LOCATION = 'suggested_location';
  public static readonly SUGGESTED_LPN_PARAMETER = 'suggested_lpn';
  public static readonly SUGGESTED_ORDER_PREPARATION_LPN_NOT_FOUND = 'SUGGESTED_ORDER_PREPARATION_LPN_NOT_FOUND';
  public static readonly SUGGESTED_PICKING_NUMBER = 'suggested_picking_number';
  public static readonly SUGGESTED_PUT_AWAY = 'suggested_put_away';
  public static readonly SUGGESTED_RECEIPT_NUMBER = 'suggested_receipt_number';
  public static readonly SUGGESTEDLOCATION = 'suggestedLocation';
  public static readonly SUMMARY = 'summary';
  public static readonly SUPPLIER = 'supplier';
  public static readonly TASK = 'task';
  public static readonly TASK_CANNOT_BE_CREATED = 'TASK_CANNOT_BE_CREATED';
  public static readonly TASK_LPN_IN_PROGRESS = 'TASK_LPN_IN_PROGRESS';
  public static readonly TASK_NOT_FOUND = 'TASK_NOT_FOUND';
  public static readonly TASK_PICKING_LPN_TYPE = 'PickingLpn';
  public static readonly TASK_PRIORITY_NOT_FOUND = 'TASK_PRIORITY_NOT_FOUND';
  public static readonly TASK_REPORTS_NOT_FOUND = 'TASK_REPORTS_NOT_FOUND';
  public static readonly TASK_ROUTE = 'task/select';
  public static readonly TASK_STATUS_AVAILABLE = 'Available';
  public static readonly TASK_STATUS_COMPLETED = 'Done';
  public static readonly TASK_TYPE = 'PutAway';
  public static readonly TASK_TYPE_FORM = 'taskType';
  public static readonly TEMPERATURE = 'temperature';
  public static readonly TEN = 10;
  public static readonly THIRD_COUNT = 3;
  public static readonly THIRD_DYNAMIC_ATTRIBUTE_REQUIRED_FORM = 'isDynamicAttribute3Required';
  public static readonly THIRD_DYNAMIC_ATTRIBUTE_VALID = 'isDynamicAttribute3Valid';
  public static readonly THIRTEEN = 13;
  public static readonly THIRTY = 30;
  public static readonly THREE = 3;
  public static readonly TIME_EXPIRATION_DATE_KEY = 'timeExpirationDate';
  public static readonly TIME_FORMAT = 'HH:mm:ss';
  public static readonly TIME_ZONE = 'timeZone';
  public static readonly TIMEOUT = 2000;
  public static readonly TIMEOUT_FOR_REDIRECT = 1500;
  public static readonly TIMEOUT_RECEIPT_ASN = 1500;
  public static readonly TIMEOUT_RECEIPT_EXECUTION = 1000;
  public static readonly TO_INTERFACE = 'TO_INTERFACE';
  public static readonly TO_MAGENTO = 'TO_MAGENTO';
  public static readonly TOTAL_QUANTITY_SERIALS_PRODUCT = 'totalQuantitySerialsProduct';
  public static readonly TRACKING_NUMBER_PER_PALLET = 'tracking_number_per_pallet';
  public static readonly TRANSLATED_DASHBOARD_TYPE = 'translatedDashboardType';
  public static readonly TRANSLATED_NAME = 'translatedName';
  public static readonly TRANSLATED_TASK_TYPE = 'translatedTaskType';
  public static readonly TRUCK_ADD = 'truck-arrival/add';
  public static readonly TRUCK_ARRIVAL = 'truck-arrival';
  public static readonly TRUCK_ARRIVAL_CANNOT_BE_FOUND = 'TRUCK_ARRIVAL_CANNOT_BE_FOUND';
  public static readonly TRUCK_ARRIVAL_DIFFERENT_ORDER_POLICIES = 'TRUCK_ARRIVAL_DIFFERENT_ORDER_POLICIES';
  public static readonly TRUCK_ARRIVAL_SECURE_NUMBER = 'truck_arrival_secure_number';
  public static readonly TRUCK_ARRIVAL_TEMPERATURE = 'truck_arrival_temperature';
  public static readonly TRUCK_EDIT = 'truck-arrival/edit/';
  public static readonly TRUE = 'true';
  public static readonly TWENTY_FIVE = 25;
  public static readonly TWENTY_THREE = 23;
  public static readonly TWO = 2;
  public static readonly TWO_COLUMNS = 2;
  public static readonly TWO_HUNDRED_AND_FIFTY = 250;
  public static readonly TYPE = 'type';
  public static readonly TYPE_RECEIVING_WITH_PURCHASE_ORDER = 'ReceivingWithPurchaseOrder';
  public static readonly TYPE_RECEIVING_WITHOUT_PURCHASE_ORDER = 'ReceivingWithoutPurchaseOrder';
  public static readonly UNCOMPLETED_ORDER_DETAILS_NOT_FOUND = 'UNCOMPLETED_ORDER_DETAILS_NOT_FOUND';
  public static readonly UNDERSCORE = '_';
  public static readonly UNEXPECTED_FILE = 'UNEXPECTED_FILE';
  public static readonly UNIQUE_ACCOUNT = 1;
  public static readonly UOM_HAS_RELATED_FOOTPRINT = 'UOM_HAS_RELATED_FOOTPRINT';
  public static readonly UOMS_NOT_FOUND = 'UOMS_NOT_FOUND';
  public static readonly UPDATE_INTERFACED_RECEIPT_ORDERS = 'update_interfaced_receipt_orders';
  public static readonly UPDATE_INTERFACED_SHIPMENT_ORDERS = 'update_interfaced_shipment_orders';
  public static readonly UPDATE_QUANTITY = 0;
  public static readonly UPPER_CASE_TRUE = 'True';
  public static readonly UPSEQUENCE = 'upSequence';
  public static readonly URL_PATTERN = 'https?://.*';
  public static readonly USER_DONT_HAVE_NECESSARY_ZONES_ASSIGNED = 'USER_DONT_HAVE_NECESSARY_ZONES_ASSIGNED';
  public static readonly USER_HAS_ACTIVE_TOKEN = 'USER_HAS_ACTIVE_TOKEN';
  public static readonly USER_IS_BLOCKED = 'USER_IS_BLOCKED';
  public static readonly USER_PASSWORD_KEY = 'updatePassword';
  public static readonly USER_ROUTE = '/users';
  public static readonly VALID_PIECES_SCANNED = 'valid_pieces';
  public static readonly VALID_PIECES_SCANNED_ICON_PATH = 'assets/icons/packstation/valid_pieces.svg';
  public static readonly VALIDATION_ROUTE = 'validation';
  public static readonly VALIDATION_TYPE = 'Validation';
  public static readonly VALUE_FIELD = 'value';
  public static readonly VALUE_PARAM_FALSE = '0';
  public static readonly VEHICLE = 'vehicle';
  public static readonly VEHICLE_TYPE = 'vehicleType';
  public static readonly VIEW_DETAIL_AUDIT = 'view-detail-audit/';
  public static readonly VIEW_REPORT_AUDIT = 'report-audit';
  public static readonly WAREHOUSE_MAX_LENGTH = 150;
  public static readonly WAVE = 'wave';
  public static readonly WAVE_ASSIGNED_STATUS = 'Assigned';
  public static readonly WAVE_FOUND_IN_ANOTHER_STATUS = 'WAVE_FOUND_IN_ANOTHER_STATUS';
  public static readonly WAVE_ID = 3;
  public static readonly WAVE_NOT_FOUND = 'WAVE_NOT_FOUND';
  public static readonly WAVE_PRIORITY = 'wavePriority';
  public static readonly WAVE_STEP = 'waveStep';
  public static readonly WEBSOCKET = 'websocket';
  public static readonly WEDNESDAY_DATE_PICKER = 'M';
  public static readonly WEP = 'wep';
  public static readonly WEP_LOGO_URL = 'assets/svg/wep-logo.svg';
  public static readonly WEP_OPS_INTEGRATOR_PARAMETER = 'wep_ops_integrator';
  public static readonly WHITE_SPACE = ' ';
  public static readonly WIDTH_INPUT = { 'width': '50vw' };
  public static readonly WIDTH_INPUT_RECEIPT_PIECE = { 'width': '30vw' };
  public static readonly WIDTH_INPUT_SIZE = { 'width': '17vw' };
  public static readonly WITH_OUT_ORDER_STATUS = 'withOutOrdersStatus';
  public static readonly WITH_OUT_ORDER_STYLE = 'withOutOrders';
  public static readonly WITH_OUT_PRODUCT_STATUS = 'withOutProductStatus';
  public static readonly WITH_OUT_PRODUCT_STYLE = 'withOutProduct';
  public static readonly WORK_REFERENCE_ID = 1;
  public static readonly WORKZONES_NOT_FOUND = 'WORKZONES_NOT_FOUND';
  public static readonly YEAR_FORMAT = 'YYYY-MM-DD';
  public static readonly YEAR_RANGE = '2000:2030';
  public static readonly YES_ANSWER = 's';
  public static readonly ZERO = 0;
  public static readonly ZERO_QUANTITY = 0;
  public static readonly ZERO_STRING = '0';
  public static readonly ZERO_PREFIX = '000';
  public static readonly ZERO_DEFAULT_VOLUME = '0.01';
  public static readonly ZERO_TEMPERATURE = 0;
  public static readonly ZOOM = 13;
}
