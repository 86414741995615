import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { PagesComponent } from './pages.component';
import { SystemLabelsResolver } from './shared/system-labels/system-labels-resolver.service';

const appRoutes: Routes = [
  /* DASHBOARD */
  { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
  {
    path: '', component: PagesComponent, children: [
      // This section is dedicated to place lazy loading routes.
      { path: 'home', component: HomeComponent },
      /* DETAIL-PUT-AWAY */
      // detail-put-away
      {
        path: 'detail-put-away',
        loadChildren: () => import('./process/detail-put-away/detail-put-away.module').then(m => m.DetailPutAwayModule)
      },
      {
        path: 'detail-put-away/:id',
        loadChildren: () => import('./process/detail-put-away/detail-put-away.module').then(m => m.DetailPutAwayModule)
      },
      /* CATALOG */
      // Accessory
      { path: 'accessory', loadChildren: () => import('./catalog/accessory/accessory.module').then(m => m.AccessoryModule) },
      { path: 'accessory/add', loadChildren: () => import('./catalog/accessory/add/accessory-add.module').then(m => m.AccessoryAddModule) },
      {
        path: 'accessory/edit/:id', loadChildren: () =>
          import('./catalog/accessory/edit/accessory-edit.module').then(m => m.AccessoryEditModule)
      },
      // Account
      {
        path: 'account', loadChildren: () =>
          import('./catalog/account/account.module').then(m => m.AccountModule)
      },
      {
        path: 'account/add', loadChildren: () =>
          import('./catalog/account/add/account-add.module').then(m => m.AccountAddModule)
      },
      {
        path: 'account/edit/:id', loadChildren: () =>
          import('./catalog/account/edit/account-edit.module').then(m => m.AccountEditModule)
      },
      // Address
      {
        path: 'address', loadChildren: () =>
          import('./catalog/address/address.module').then(m => m.AddressModule)
      },
      {
        path: 'address/add', loadChildren: () =>
          import('./catalog/address/add/address-add.module').then(m => m.AddressAddModule)
      },
      {
        path: 'address/edit/:id', loadChildren: () =>
          import('./catalog/address/edit/address-edit.module').then(m => m.AddressEditModule)
      },
      // Area
      {
        path: 'area', loadChildren: () =>
          import('./catalog/area/area.module').then(m => m.AreaModule)
      },
      {
        path: 'area/add', loadChildren: () =>
          import('./catalog/area/add/area-add.module').then(m => m.AreaAddModule)
      },
      {
        path: 'area/edit/:id', loadChildren: () =>
          import('./catalog/area/edit/area-edit.module').then(m => m.AreaEditModule)
      },
      {
        path: 'area/area-type/:id', loadChildren: () =>
          import('./catalog/area/area-type/area-type.module').then(m => m.AreaAreaTypeModule)
      },
      // Building
      {
        path: 'building', loadChildren: () =>
          import('./catalog/building/building.module').then(m => m.BuildingModule)
      },
      {
        path: 'building/add', loadChildren: () =>
          import('./catalog/building/add/building-add.module').then(m => m.BuildingAddModule)
      },
      {
        path: 'building/edit/:id', loadChildren: () =>
          import('./catalog/building/edit/building-edit.module').then(m => m.BuildingEditModule)
      },
      // Progression
      {
        path: 'progression', loadChildren: () =>
          import('./catalog/progression/progression.module').then(m => m.ProgressionModule)
      },
      {
        path: 'progression/add', loadChildren: () =>
          import('./catalog/progression/add/progression-add.module').then(m => m.ProgressionAddModule)
      },
      {
        path: 'progression/edit/:id', loadChildren: () =>
          import('./catalog/progression/edit/progression-edit.module').then(m => m.ProgressionEditModule)
      },
      // Work-Area
      {
        path: 'work-area', loadChildren: () =>
          import('./catalog/work-area/work-area.module').then(m => m.WorkAreaModule)
      },
      {
        path: 'work-area/add', loadChildren: () =>
          import('./catalog/work-area/add/work-area-add.module').then(m => m.WorkAreaAddModule)
      },
      {
        path: 'work-area/edit/:id', loadChildren: () =>
          import('./catalog/work-area/edit/work-area-edit.module').then(m => m.WorkAreaEditModule)
      },
      // Customer
      {
        path: 'customer', loadChildren: () =>
          import('./catalog/customer/customer.module').then(m => m.CustomerModule)
      },
      {
        path: 'customer/add', loadChildren: () =>
          import('./catalog/customer/add/customer-add.module').then(m => m.CustomerAddModule)
      },
      {
        path: 'customer/edit/:id', loadChildren: () =>
          import('./catalog/customer/edit/customer-edit.module').then(m => m.CustomerEditModule)
      },
      // Carrier
      {
        path: 'carrier', loadChildren: () =>
          import('./catalog/carrier/carrier.module').then(m => m.CarrierModule)
      },
      {
        path: 'carrier/add', loadChildren: () =>
          import('./catalog/carrier/add/carrier-add.module').then(m => m.CarrierAddModule)
      },
      {
        path: 'carrier/edit/:id', loadChildren: () =>
          import('./catalog/carrier/edit/carrier-edit.module').then(m => m.CarrierEditModule)
      },
      // Reason
      {
        path: 'reason', loadChildren: () =>
          import('./catalog/reason/reason.module').then(m => m.ReasonModule)
      },
      {
        path: 'reason/add', loadChildren: () =>
          import('./catalog/reason/add/reason-add.module').then(m => m.ReasonAddModule)
      },
      {
        path: 'reason/edit/:id', loadChildren: () =>
          import('./catalog/reason/edit/reason-edit.module').then(m => m.ReasonEditModule)
      },
      // Reason
      {
        path: 'family', loadChildren: () =>
          import('./catalog/family/family.module').then(m => m.FamilyModule)
      },
      {
        path: 'family/add', loadChildren: () =>
          import('./catalog/family/add/family-add.module').then(m => m.FamilyAddModule)
      },
      {
        path: 'family/edit/:id', loadChildren: () =>
          import('./catalog/family/edit/family-edit.module').then(m => m.FamilyEditModule)
      },
      // Footprint
      {
        path: 'footprint', loadChildren: () =>
          import('./catalog/footprint/footprint.module').then(m => m.FootprintModule)
      },
      {
        path: 'footprint/product/:productId', loadChildren: () =>
          import('./catalog/footprint/footprint.module').then(m => m.FootprintModule)
      },
      {
        path: 'footprint/add', loadChildren: () =>
          import('./catalog/footprint/add/footprint-add.module').then(m => m.FootprintAddModule)
      },
      {
        path: 'footprint/add-detail/:id',
        loadChildren: () =>
          import('./catalog/footprint/add-detail/footprint-add-detail.module').then(m => m.FootprintAddDetailModule)
      },
      {
        path: 'footprint/edit/:id', loadChildren: () =>
          import('./catalog/footprint/edit/footprint-edit.module').then(m => m.FootprintEditModule)
      },
      {
        path: 'footprint/edit-detail/:id/fromProduct/:productId',
        loadChildren: () =>
          import('./catalog/footprint/edit-detail/footprint-edit-detail.module').then(m => m.FootprintEditDetailModule)
      },
      {
        path: 'footprint/edit-detail/:footId/:footDetId',
        loadChildren: () =>
          import('./catalog/footprint/edit-detail/footprint-edit-detail.module').then(m => m.FootprintEditDetailModule)
      },
      {
        path: 'footprint/edit-detail/:footId/:footDetId/fromProduct/:productId',
        loadChildren: () =>
          import('./catalog/footprint/edit-detail/footprint-edit-detail.module').then(m => m.FootprintEditDetailModule)
      },
      {
        path: 'footprint/edit/:id/fromProduct/:productId',
        loadChildren: () =>
          import('./catalog/footprint/edit/footprint-edit.module').then(m => m.FootprintEditModule)
      },
      {
        path: 'footprint/add-detail/:id/toProduct/:productId',
        loadChildren: () =>
          import('./catalog/footprint/add-detail/footprint-add-detail.module').then(m => m.FootprintAddDetailModule)
      },
      {
        path: 'footprint/add/toProduct/:productId', loadChildren: () =>
          import('./catalog/footprint/add/footprint-add.module').then(m => m.FootprintAddModule)
      },
      {
        path: 'footprint/view-detail/:id', loadChildren: () =>
          import('./catalog/footprint/view-detail/view-detail.module').then(m => m.FootprintDetailModule)
      },
      {
        path: 'import-template',
        loadChildren: () =>
          import('./catalog/import-template/import-template.module').then((m) => m.ImportTemplateModule)
      },
      {
        path: 'import-template/process',
        loadChildren: () =>
          import('./catalog/import-template/import-process/import-process.module').then((m) => m.ImportProcessModule)
      },
      {
        path: 'import-template/action',
        loadChildren: () =>
          import('./catalog/import-template/import-process/import-process.module').then((m) => m.ImportProcessModule)
      },
      // Inventory Status
      {
        path: 'inventory-status', loadChildren: () =>
          import('./catalog/inventory-status/inventory-status.module').then(m => m.InventoryStatusModule)
      },
      {
        path: 'inventory-status/add',
        loadChildren: () =>
          import('./catalog/inventory-status/add/inventory-status-add.module').then(m => m.InventoryStatusAddModule)
      },
      {
        path: 'inventory-status/edit/:id',
        loadChildren: () =>
          import('./catalog/inventory-status/edit/inventory-status-edit.module').then(m => m.InventoryStatusEditModule)
      },
      // Location
      {
        path: 'location', loadChildren: () =>
          import('./catalog/location/location.module').then(m => m.LocationModule)
      },
      {
        path: 'location/add', loadChildren: () =>
          import('./catalog/location/add/location-add.module').then(m => m.LocationAddModule)
      },
      {
        path: 'location/edit/:id', loadChildren: () =>
          import('./catalog/location/edit/location-edit.module').then(m => m.LocationEditModule)
      },
      {
        path: 'location/edit-batch',
        loadChildren: () =>
          import('./catalog/location/edit-batch/location-edit-batch.module').then(m => m.LocationEditBatchModule)
      },
      {
        path: 'location/print-ticket',
        loadChildren: () =>
          import('./catalog/location/print-ticket/location-print-ticket.module').then(m => m.LocationPrintTicketModule)
      },
      // Subfamily
      {
        path: 'subfamily', loadChildren: () =>
          import('./catalog/subfamily/subfamily.module').then(m => m.SubfamilyModule)
      },
      {
        path: 'subfamily/add', loadChildren: () =>
          import('./catalog/subfamily/add/subfamily-add.module').then(m => m.SubfamilyAddModule)
      },
      {
        path: 'subfamily/edit/:id', loadChildren: () =>
          import('./catalog/subfamily/edit/subfamily-edit.module').then(m => m.SubfamilyEditModule)
      },
      // Warehouse
      {
        path: 'warehouse', loadChildren: () =>
          import('./catalog/warehouse/warehouse.module').then(m => m.WarehouseModule)
      },
      {
        path: 'warehouse/add', loadChildren: () =>
          import('./catalog/warehouse/add/warehouse-add.module').then(m => m.WarehouseAddModule)
      },
      {
        path: 'warehouse/edit/:id', loadChildren: () =>
          import('./catalog/warehouse/edit/warehouse-edit.module').then(m => m.WarehouseEditModule)
      },
      // WorkZone
      {
        path: 'work-zone', loadChildren: () =>
          import('./catalog/work-zone/work-zone.module').then(m => m.WorkZoneModule)
      },
      {
        path: 'work-zone/add', loadChildren: () =>
          import('./catalog/work-zone/add/work-zone-add.module').then(m => m.WorkZoneAddModule)
      },
      {
        path: 'work-zone/edit/:id', loadChildren: () =>
          import('./catalog/work-zone/edit/work-zone-edit.module').then(m => m.WorkZoneEditModule)
      },
      // UOM
      {
        path: 'uom', loadChildren: () =>
          import('./catalog/uom/uom.module').then(m => m.UOMModule)
      },
      {
        path: 'uom/add', loadChildren: () =>
          import('./catalog/uom/add/uom-add.module').then(m => m.UOMAddModule)
      },
      {
        path: 'uom/edit/:id', loadChildren: () =>
          import('./catalog/uom/edit/uom-edit.module').then(m => m.UOMEditModule)
      },
      // Equipment
      {
        path: 'equipment', loadChildren: () =>
          import('./catalog/equipment/equipment.module').then(m => m.EquipmentModule)
      },
      {
        path: 'equipment/add', loadChildren: () =>
          import('./catalog/equipment/add/equipment-add.module').then(m => m.EquipmentAddModule)
      },
      {
        path: 'equipment/edit/:id', loadChildren: () =>
          import('./catalog/equipment/edit/equipment-edit.module').then(m => m.EquipmentEditModule)
      },
      // Equipment Type
      {
        path: 'equipment-type', loadChildren: () =>
          import('./catalog/equipment-type/equipment-type.module').then(m => m.EquipmentTypeModule)
      },
      {
        path: 'equipment-type/add', loadChildren: () =>
          import('./catalog/equipment-type/add/equipment-type-add.module').then(m => m.EquipmentTypeAddModule)
      },
      {
        path: 'equipment-type/edit/:id',
        loadChildren: () =>
          import('./catalog/equipment-type/edit/equipment-type-edit.module').then(m => m.EquipmentTypeEditModule)
      },
      // Product
      {
        path: 'product', loadChildren: () =>
          import('./catalog/product/product.module').then(m => m.ProductModule)
      },
      {
        path: 'product/add', loadChildren: () =>
          import('./catalog/product/add/product-add.module').then(m => m.ProductAddModule)
      },
      {
        path: 'product/edit/:id', loadChildren: () =>
          import('./catalog/product/edit/product-edit.module').then(m => m.ProductEditModule)
      },
      {
        path: 'alter-number/:productId', loadChildren: () =>
          import('./catalog/product/alterNumbers/alter-number.module').then(m => m.AlterNumberModule)
      },
      // Vehicle
      {
        path: 'vehicle', loadChildren: () =>
          import('./catalog/vehicle/vehicle.module').then(m => m.VehicleModule)
      },
      {
        path: 'vehicle/add', loadChildren: () =>
          import('./catalog/vehicle/add/vehicle-add.module').then(m => m.VehicleAddModule)
      },
      {
        path: 'vehicle/edit/:id', loadChildren: () =>
          import('./catalog/vehicle/edit/vehicle-edit.module').then(m => m.VehicleEditModule)
      },
      {
        path: 'vehicle-type', loadChildren: () =>
          import('./catalog/vehicle-type/vehicle-type.module').then(m => m.VehicleTypeModule)
      },
      {
        path: 'vehicle-type/add', loadChildren: () =>
          import('./catalog/vehicle-type/add/vehicle-type-add.module').then(m => m.VehicleTypeAddModule)
      },
      {
        path: 'vehicle-type/edit/:id', loadChildren: () =>
          import('./catalog/vehicle-type/edit/vehicle-type-edit.module').then(m => m.VehicleTypeEditModule)
      },
      // Supplier
      {
        path: 'supplier', loadChildren: () =>
          import('./catalog/supplier/supplier.module').then(m => m.SupplierModule)
      },
      {
        path: 'supplier/add', loadChildren: () =>
          import('./catalog/supplier/add/supplier-add.module').then(m => m.SupplierAddModule)
      },
      {
        path: 'supplier/edit/:id', loadChildren: () =>
          import('./catalog/supplier/edit/supplier-edit.module').then(m => m.SupplierEditModule)
      },
      /* ADMINISTRATION */
      // Report Configuration
      {
        path: 'report-configuration', loadChildren: () =>
          import('./administration/report-configuration/report-configuration.module').then(m => m.ReportConfigurationModule)
      },
      // Receipt report
      {
        path: 'receipt-report', loadChildren: () =>
          import('./report/receipt-report/receipt-report.module').then(m => m.ReceiptReportModule)
      },
      // Archiving
      {
        path: 'archiving', loadChildren: () =>
          import('./administration/archiving/archiving.module').then(m => m.ArchivingModule)
      },
      // Assignment Policy
      {
        path: 'assignment-policy',
        loadChildren: () =>
          import('./administration/assignment-policy/assignment-policy.module').then(m => m.AssignmentPolicyModule)
      },
      {
        path: 'assignment-policy/add',
        loadChildren: () =>
          import('./administration/assignment-policy/add/assignment-policy-add.module').then(m => m.AssignmentPolicyAddModule)
      },
      {
        path: 'assignment-policy/edit/:id',
        loadChildren: () =>
          import('./administration/assignment-policy/edit/assignment-policy-edit.module').then(m => m.AssignmentPolicyEditModule)
      },
      // Appintment Configuration
      {
        path: 'appointment-configuration',
        loadChildren: () =>
          import('./administration/appointment-configuration/appointment-configuration.module').then(m => m.AppointmentConfigurationModule)
      },
      // Barcode BarcodeConfiguration
      {
        path: 'barcode-configuration',
        loadChildren: () =>
          import('./administration/barcode-configuration/barcode-configuration.module').then(m => m.BarcodeConfigurationModule)
      },
      // License
      {
        path: 'license', loadChildren: () =>
          import('./administration/license/license.module').then(m => m.LicenseModule)
      },
      // Printer label
      {
        path: 'printer-label', loadChildren: () =>
          import('./administration/printer-label/printer-label.module').then(m => m.PrinterLabelModule)
      },
      {
        path: 'printer-label/create/step-1',
        loadChildren: () =>
          import('./administration/printer-label/add-file/printer-label-add-file.module').then(m => m.PrinterLabelAddFileModule)
      },
      {
        path: 'printer-label/create/step-2/:filename',
        loadChildren: () =>
          import('./administration/printer-label/add-label/printer-label-add-label.module').then(m => m.PrinterLabelAddLabelModule)
      },
      {
        path: 'printer-label/create/:id/edit',
        loadChildren: () =>
          import('./administration/printer-label/edit/printer-label-edit.module').then(m => m.PrinterLabelEditModule)
      },
      // Label management
      {
        path: 'labels', loadChildren: () =>
          import('./administration/label-management/label.module').then(m => m.LabelModule)
      },
      // RF monitor
      {
        path: 'rf-monitor', loadChildren: () =>
          import('./administration/rf-monitor/rf-monitor.module').then(m => m.RfMonitorModule)
      },
      // Put away policy
      {
        path: 'put-away-policy', loadChildren: () =>
          import('./administration/put-away-policy/put-away-policy.module').then(m => m.PutAwayPolicyModule)
      },
      {
        path: 'put-away-policy/add',
        loadChildren: () =>
          import('./administration/put-away-policy/add/put-away-policy-add.module').then(m => m.PutAwayPolicyAddModule)
      },
      {
        path: 'put-away-policy/edit/:id',
        loadChildren: () =>
          import('./administration/put-away-policy/edit/put-away-policy-edit.module').then(m => m.PutAwayPolicyEditModule)
      },
      // Policy param
      {
        path: 'policy-param', loadChildren: () =>
          import('./administration/policy-param/policy-param.module').then(m => m.PolicyParamModule)
      },
      {
        path: 'policy-param/:type/:id/edit',
        loadChildren: () =>
          import('./administration/policy-param/edit/policy-param-edit.module').then(m => m.PolicyParamEditModule)
      },
      // Role
      {
        path: 'role/management', loadChildren: () =>
          import('./administration/role/role.module').then(m => m.RoleModule)
      },
      {
        path: 'role/add', loadChildren: () =>
          import('./administration/role/add/role-add.module').then(m => m.RoleAddModule)
      },
      {
        path: 'role/edit/:role', loadChildren: () =>
          import('./administration/role/edit/role-edit.module').then(m => m.RoleEditModule)
      },
      // Users
      {
        path: 'users', loadChildren: () =>
          import('./administration/user/user.module').then(m => m.UserModule)
      },
      {
        path: 'user/add', loadChildren: () =>
          import('./administration/user/add/user-add.module').then(m => m.UserAddModule)
      },
      {
        path: 'user/details/:id', loadChildren: () =>
          import('./administration/user/detail/user-detail.module').then(m => m.UserDetailModule)
      },
      {
        path: 'user/edit/:id', loadChildren: () =>
          import('./administration/user/edit/user-edit.module').then(m => m.UserEditionModule)
      },
      {
        path: 'input-field-configuration',
        loadChildren: () =>
          import('./administration/input-field-configuration/input-field-configuration.module').then(m => m.InputFieldConfigurationModule)
      },
      {
        path: 'input-field-configuration/add',
        loadChildren: () =>
          import('./administration/input-field-configuration/add/input-field-configuration-add.module').then(m =>
            m.InputFieldConfigurationAddModule)
      },
      {
        path: 'input-field-configuration/edit/:id',
        loadChildren: () =>
          import('./administration/input-field-configuration/edit/input-field-configuration-edit.module').then(m =>
            m.InputFieldConfigurationEditModule)
      },
      // Assignment-zone-user
      {
        path: 'assignment-zone-user',
        loadChildren: () =>
          import('./administration/assignment-zone-user/assignment-zone-user.module').then(m => m.AssignmentZoneUserModule)
      },
      // MOVEMENT-BEETWEN-AREA
      {
        path: 'movement-between-area',
        loadChildren: () =>
          import('./administration/movement-between-area/movement-between-area.module').then(m => m.MovementBetweenAreaModule)
      },
      {
        path: 'movement-between-area/add',
        loadChildren: () =>
          import('./administration/movement-between-area/add/movement-between-area-add.module').then(m => m.MovementBetweenAreaAddModule)
      },
      {
        path: 'movement-between-area/edit/:id',
        loadChildren: () =>
          import('./administration/movement-between-area/edit/movement-between-area-edit.module').then(m => m.MovementBetweenAreaEditModule)
      },
      // Dashboard-configuration
      {
        path: 'dashboard-configuration',
        loadChildren: () =>
          import('./administration/dashboard-configuration/dashboard-configuration.module').then(m => m.DashboardConfigurationModule)
      },
      // Workflow
      {
        path: 'workflow', loadChildren: () => import('./administration/workflow/workflow.module').then(m => m.WorkflowModule)
      },
      {
        path: 'workflow/add', loadChildren: () => import('./administration/workflow/add/workflow-add.module').then(m => m.WorkflowAddModule)
      },
      {
        path: 'workflow/edit/:id',
        loadChildren: () =>
          import('./administration/workflow/edit/workflow-edit.module').then(m => m.WorkflowEditModule)
      },
      // INVENTORY
      // Quantity Adjustment
      {
        path: 'inventory/quantity-adjustment',
        loadChildren: () =>
          import('./inventory/quantity-adjustment/quantity-adjustment.module').then(m => m.QuantityAdjustmentModule)
      },
      // Inventory Analisis
      {
        path: 'inventory/inventory-analysis',
        loadChildren: () =>
          import('./inventory/inventory-analysis/inventory-analysis.module').then(m => m.InventoryAnalysisModule)
      },
      {
        path: 'inventory-analysis/view-detail',
        loadChildren: () =>
          import('./inventory/inventory-analysis/view-detail/view-detail.module').then(m => m.InventoryAnalysisDetailModule)
      },
      // Manual Cycle Count
      {
        path: 'manual-cycle-count', loadChildren: () =>
          import('./inventory/manual-cycle-count/manual-cycle-count.module').then(m => m.ManualCycleCountModule)
      },
      // Inventory Physical Count
      {
        path: 'inventory/physical-count', loadChildren: () =>
          import('./inventory/physical-count/physical-count.module').then(m => m.PhysicalCountModule)
      },
      // Validation
      {
        path: 'inventory/validation', loadChildren: () =>
          import('./inventory/physical-count/physical-count.module').then(m => m.PhysicalCountModule)
      },
      {
        path: 'inventory/validation-report', loadChildren: () =>
          import('./inventory/validation-report/validation-report.module').then(m => m.ValidationReportModule)
      },
      // Cycle Count
      {
        path: 'cycle-count-location',
        loadChildren: () =>
          import('./inventory/cycle-count-location/cycle-count-location.module').then(m => m.CycleCountLocationModule)
      },
      {
        path: 'cycle-count-location/physical-count',
        loadChildren: () =>
          import('./inventory/cycle-count-location/physical-cycle-count/physical-cycle-count.module').then(m => m.PhysicalCycleCountModule)
      },
      {
        path: 'code-change',
        loadChildren: () => import('./inventory/code-change-product/code-change.module').then( m => m.CodeChangeModule)
      },
      {
        path: 'code-change-rf',
        loadChildren: () => import('./inventory/code-change-product/code-change-rf/code-change-rf.module').then(m => m.CodeChangeRfModule)
      },
      {
        path: 'inventory-count-configuration',
        loadChildren: () => import('./administration/inventory-count-configuration/inventory-count-configuration.module')
        .then(m => m.InventoryCountConfigurationModule)
      },
      // Order Preparation
      {
        path: 'order-preparation',
        loadChildren: () => import('./inventory/order preparation/order-preparation.module').then(m => m.OrderPreparationModule)
      },
      {
        path: 'order-preparation-process',
        loadChildren: () => import('./inventory/order preparation/order-preparation-process/order-preparation-process.module')
        .then(m => m.OrderPreparationProcessModule)
      },
      {
        path: 'order-preparation-process-task',
        loadChildren: () => import('./inventory/order preparation/order-preparation-process-task/order-preparation-process-task.module')
        .then(m => m.OrderPreparationProcessTaskModule)
      },
      {
        path: 'order-preparation-process-task/:id',
        loadChildren: () => import('./inventory/order preparation/order-preparation-process-task/order-preparation-process-task.module')
        .then(m => m.OrderPreparationProcessTaskModule)
      },
      // Load Inventory
      {
        path: 'inventory-load',
        loadChildren: () => import('./inventory/inventory-load/inventory-load.module')
        .then(m => m.InventoryLoadModule)
      },
      /* REPORTS */
      // Assignment summary
      {
        path: 'planning/assignment-summary',
        loadChildren: () =>
          import('./report/planning/assignment-summary/assignment-summary.module').then(m => m.AssignmentSummaryModule)
      },
      {
        path: 'planning/assignment-summary/wave/:id',
        loadChildren: () => import('./report/planning/assignment-summary/wave-detail/assignment-summary-wave-detail.module')
        .then(m => m.AssignmentSummaryWaveDetailModule)
      },
      {
        path: 'planning/assignment-summary/wave/:waveId/shipment-order/:id',
        loadChildren: () =>
        import('./report/planning/assignment-summary/wave-detail/shipment-order-detail/assignment-summary-shipment-order-detail.module')
        .then(m => m.AssignmentSummaryShipmentOrderDetailModule)
      },
      // Inventory-location-report
      {
        path: 'inventory-report-location',
        loadChildren: () =>
          import('./shared/report-rss-screens/inventory-report-location/inventory-report-location.module')
          .then(m => m.InventoryReportLocationModule)
      },
      // Wave Picking
      {
        path: 'planning/assignment-summary/wave-picking/:id',
        loadChildren: () => import('./report/planning/assignment-summary/wave-picking/wave-picking.module').then(m =>
          m.WavePickingModule)
      },
      {
        path: 'planning/assignment-summary/wave-picking/:waveId/task/:taskId',
        loadChildren: () =>
        import('./report/planning/assignment-summary/wave-picking/wave-picking-complement/wave-picking-complement.module')
        .then(m => m.WavePickingComplementModule)
      },
      {
        path: 'planning/assignment-summary/wave-picking/incidents/:id',
        loadChildren: () => import('./report/planning/assignment-summary/wave-picking/wave-picking-incident/wave-picking-incident.module')
        .then(m => m.WavePickingIncidentModule)
      },
      // Tasks by wave
      {
        path: 'planning/tasks-by-wave',
        loadChildren: () =>
          import('./report/planning/tasks-by-wave/tasks-by-wave.module').then(m => m.TasksByWaveModule)
      },
      {
        path: 'planning/tasks-by-wave/wave/:id/work-reference/:workReference',
        loadChildren: () =>
          import('./report/planning/tasks-by-wave/work-reference-detail/work-reference-detail.module').then(m =>
            m.WorkReferenceDetailModule)
      },
      // Movement history
      {
        path: 'movement/history', loadChildren: () =>
          import('./report/inventory/movement-history/movement-history.module').then(m => m.MovementHistoryModule)
      },
      /* TASKS */
      {
        path: 'tasks', loadChildren: () =>
          import('./process/task/task.module').then(m => m.TaskModule)
      },
      {
        path: 'task/select', loadChildren: () =>
          import('./process/task-select/task-select.module').then(m => m.TaskSelectModule)
      },
      // Release Task
      {
        path: 'release-task',
        loadChildren: () =>
          import('./process/task/release-task/release-task.module').then(m => m.ReleaseTaskModule)
      },
      /* RECEIPT */
      {
        path: 'receipt', loadChildren: () =>
          import('./process/receipt/receipt.module').then(m => m.ReceiptModule)
      },
      {
        path: 'receipt/detail/:lpn/:folio', loadChildren: () =>
          import('./process/receipt/detail/receipt-detail.module').then(m => m.ReceiptDetailModule)
      },
      /* RECEIPT PER PIECE */
      {
        path: 'receipt-piece',
        loadChildren: () =>
          import('./process/receipt/receipt-pieces/receipt-piece-process/receipt-piece.module').then(m => m.ReceiptPieceModule)
      },
      {
        path: 'receipt-piece/detail/:id/receipt-detail/:receiptId',
        loadChildren: () => import('./process/receipt/receipt-pieces/receipt-piece-process/detail/receipt-per-piece-detail.module')
        .then(m => m.ReceiptPerPieceDetailModule)
      },
      // progress-receipt-order
      {
        path: 'progress-receipt-order',
        loadChildren: () =>
          import('./process/progress-receipt-order/progress-receipt-order.module').then(m => m.ProgressReceiptOrderModule)
      },
      {
        path: 'progress-receipt-order/view-detail/:folio',
        loadChildren: () =>
          import('./process/progress-receipt-order/view-detail/view-detail.module').then(m => m.ProgressReceiptOrderDetailModule)
      },
      {
        path: 'progress-receipt-order/view-detail-lpn/:folio',
        loadChildren: () =>
          import('./process/progress-receipt-order/view-lpn-detail/view-lpn-detail.module').then(m => m.ViewLpnDetailModule)
      },
      /* REALLOCATE */
      // Reallocate lpn
      {
        path: 'reallocate/lpn', loadChildren: () =>
          import('./process/reallocate/reallocate-lpn/reallocate-lpn.module').then(m => m.ReallocateLPNModule)
      },
      // Reallocate product
      {
        path: 'reallocate/product',
        loadChildren: () =>
          import('./process/reallocate/reallocate-product/reallocate-product.module').then(m => m.ReallocateProductModule)
      },
      // Reallocate report
      {
        path: 'reallocate/report',
        loadChildren: () =>
          import('./process/reallocate/reallocate-report/reallocate-report.module').then(m => m.ReallocateReportModule)
      },
      // Reallocate case
      {
        path: 'reallocate/case',
        loadChildren: () =>
          import('./process/reallocate/reallocate-case/reallocate-case.module').then(m => m.ReallocateCaseModule)
      },
      // Inventory report
      {
        path: 'inventory/report', loadChildren: () =>
          import('./report/inventory/inventory-report/inventory-report.module').then(m => m.InventoryReportModule)
      },
      {
        path: 'inventory-report/check-inventory-location/:name',
        loadChildren: () =>
            import('./report/inventory/inventory-report/check-inventory-location/check-inventory-location.module').then(m =>
              m.CheckInventoryLocationModule)
      },
      {
        path: 'inventory-report/check-inventory-lpn/:id',
        loadChildren: () =>
          import('./report/inventory/inventory-report/check-inventory-lpn/check-inventory-lpn.module').then(m => m.CheckInventoryLpnModule)
      },
      {
        path: 'inventory-report/check-inventory-product/:id',
        loadChildren: () =>
            import('./report/inventory/inventory-report/check-inventory-product/check-inventory-product.module').then(m =>
              m.CheckInventoryProductModule)
      },
      {
        path: 'inventory-report/check-inventory-case-id/:id',
        loadChildren: () =>
            import('./report/inventory/inventory-report/check-inventory-case-id/check-inventory-case-id.module').then(m =>
              m.CheckInventoryCaseIdModule)
      },
      /* KITTING */
      {
        path: 'kitting/material-list',
        loadChildren: () =>
          import('./process/kitting/material-list/material-list.module').then(m => m.MaterialListModule)
      },
      {
        path: 'kitting/material-list/add',
        loadChildren: () =>
          import('./process/kitting/material-list/add/material-list-add.module').then(m => m.MaterialListAddModule)
      },
      {
        path: 'kitting/material-list/edit/:id',
        loadChildren: () =>
          import('./process/kitting/material-list/edit/material-list-edit.module').then(m => m.MaterialListEditModule)
      },
      {
        path: 'kitting/kit/:id',
        loadChildren: () =>
          import('./process/kitting/material-list/detail/material-list-detail.module').then(m => m.MaterialListDetailModule)
      },
      /* Kitting-order*/
      {
        path: 'kitting/kitting-order',
        loadChildren: () =>
          import('./process/kitting/kitting-order/kitting-order.module').then(m => m.KittingOrderModule)
      },
      {
        path: 'kitting/kitting-order/add',
        loadChildren: () =>
          import('./process/kitting/kitting-order/add/kitting-order-add.module').then(m => m.KittingOrderAddModule)
      },
      {
        path: 'kitting/kitting-order/edit/:id',
        loadChildren: () =>
          import('./process/kitting/kitting-order/edit/kitting-order-edit.module').then(m => m.KittingOrderEditModule)
      },
      {
        path: 'kitting/kitting-order/detail/:kitId',
        loadChildren: () =>
          import('./process/kitting/kitting-order/add/detail/kitting-order-detail.module').then(m => m.KittingOrderDetailModule)
      },
      {
        path: 'kitting/kitting-order/edit/detail/:kitId',
        loadChildren: () =>
          import('./process/kitting/kitting-order/edit/detail/kitting-order-detail-edit.module').then(m => m.KittingOrderDetailEditModule)
      },
      /* Kit reception */
      {
        path: 'kitting/kit-reception',
        loadChildren: () =>
          import('./process/kitting/kit-reception/kit-reception.module').then(m => m.KitReceptionModule)
      },
      {
        path: 'kitting/kit-reception/:id/list',
        loadChildren: () =>
          import('./process/kitting/kit-reception/list/kit-reception-list.module').then(m => m.KitReceptionListModule)
      },
      /* Take apart kit */
      {
        path: 'kitting/take-apart', loadChildren: () =>
          import('./process/kitting/take-apart/take-apart.module').then(m => m.TakeApartModule)
      },
      /* PICKING */
      {
        path: 'picking', loadChildren: () =>
          import('./process/picking/picking.module').then(m => m.PickingModule)
      },
      {
        path: 'picking/detail/:lpn', loadChildren: () =>
          import('./process/picking/detail/picking-detail.module').then(m => m.PickingDetailModule)
      },
      {
        path: 'picking/lpn', loadChildren: () =>
          import('./process/rf/picking-lpn/picking-lpn.module').then(m => m.PickingLpnModule)
      },
      {
        path: 'picking/rf', loadChildren: () =>
          import('./process/rf/picking/picking-rf.module').then(m => m.PickingRfModule)
      },
      {
        path: 'picking/select', loadChildren: () =>
          import('./process/picking/picking-selected/picking-select.module').then(m => m.PickingSelectModule)
      },
      {
        path: 'progress-shipment-order',
        loadChildren: () =>
          import('./process/progress-shipment-order/progress-shipment-order.module').then(m => m.ProgressShipmentOrderModule)
      },
      {
        path: 'progress-shipment-order/detail/:id',
        loadChildren: () =>
          import('./process/progress-shipment-order/shipment-product-detail/shipment-product-detail.module').then(m =>
            m.ShipmentProductDetailModule)
      },
      {
        path: 'simple-picking-pallet',
        loadChildren: () =>
          import('./process/picking/simple-picking-pallet/simple-picking-pallet.module').then(m =>
            m.SimplePickingPalletModule)
      },
      // Planning
      {
        path: 'planning', loadChildren: () =>
          import('./planning/planning.module').then(m => m.PlanningModule)
      },
      {
        path: 'picking-fulfillment', loadChildren: () =>
          import('./process/picking-fulfillment/picking-fulfillment.module').then(m => m.PickingFulfillmentModule)
      },
      {
        path: 'picking-fulfillment-detail/:id',
        loadChildren: () =>
          import('./process/picking-fulfillment/detail/picking-fulfillment-detail.module').then(m => m.PickingFulfillmentDetailModule)
      },
      // Picking-List
      {
        path: 'picking-list', loadChildren: () =>
          import('./process/picking-list/picking-list.module').then(m => m.PickingListModule)
      },
      {
        path: 'picking-list/:id', loadChildren: () =>
          import('./process/picking-list/picking-list.module').then(m => m.PickingListModule)
      },
      // Picking-List-Working-Order
      {
        path: 'picking-list-working-order',
        loadChildren: () =>
          import('./process/picking-list-working-order/picking-list-working-order.module').then(m => m.PickingListWorkingOrderModule)
      },
      {
        path: 'picking-list-working-order/:id',
        loadChildren: () =>
          import('./process/picking-list-working-order/picking-list-working-order.module').then(m => m.PickingListWorkingOrderModule)
      },
      // REPLENISHMENT-TASK
      {
        path: 'replenishment-task/:taskId',
        loadChildren: () =>
          import('./planning/replenishment-task/replenishment-task.module').then(m => m.ReplenishmentTaskModule)
      },
      // Picking-List-Configuration
      {
        path: 'picking-list-configuration',
        loadChildren: () =>
          import('./planning/picking-list-configuration/picking-list-configuration.module').then(m => m.PickingListConfigurationModule)
      },
      {
        path: 'picking-list-configuration/add',
        loadChildren: () =>
          import('./planning/picking-list-configuration/add/picking-list-configuration-add.module').then(m =>
            m.PickingListConfigurationAddModule)
      },
      {
        path: 'picking-list-configuration/edit/:id',
        loadChildren: () =>
          import('./planning/picking-list-configuration/edit/picking-list-configuration-edit.module').then(m =>
            m.PickingListConfigurationEditModule)
      },
      {
        path: 'planning/shipment-summary/:id', loadChildren: () =>
          import('./planning/shipment-summary/shipment-summary.module').then(m => m.ShipmentSummaryModule)
      },
      {
        path: 'planning/shipment-summary-detail/:id',
        loadChildren: () =>
          import('./planning/shipment-summary/detail/shipment-summary-detail.module').then(m => m.ShipmentSummaryDetailModule)
      },
      {
        path: 'planning/shipment-summary-detail/:id/subdetail/:id',
        loadChildren: () =>
          import('./planning/shipment-summary/shipment-summary-subdetail/shipment-summary-subdetail.module')
          .then(m => m.ShipmentSummarySubdetailModule)
      },
      {
        path: 'planning/shipment-order-detail/:id',
        loadChildren: () =>
          import('./planning/shipment-order-detail/shipment-order-detail.module').then(m => m.ShipmentOrderDetailModule)
      },
      {
        path: 'plannig/shipment-order-detail/product-detail',
        loadChildren:
          () =>
            import('./planning/shipment-order-detail/shipment-product-detail/shipment-product-detail.module').then(m =>
              m.ShipmentProductDetailModule)
      },
      {
        path: 'planning/short-allocation',
        loadChildren: () =>
          import('./planning/short-allocation/short-allocation.module').then(m => m.ShortAllocationModule)
      },
      {
        path: 'replenishment-configuration',
        loadChildren: () =>
            import('./planning/replenishment-configuration/replenishment-configuration.module').then(m =>
              m.ReplenishmentConfigurationModule)
      },
      {
        path: 'replenishment-configuration/add',
        loadChildren: () =>
            import('./planning/replenishment-configuration/add/replenishment-configuration-add.module').then(m =>
              m.ReplenishmentConfigurationAddModule)
      },
      {
        path: 'replenishment-configuration/edit/:id',
        loadChildren: () =>
            import('./planning/replenishment-configuration/edit/replenishment-configuration-edit.module').then(m =>
              m.ReplenishmentConfigurationEditModule)
      },
      {
        path: 'replenishment',
        loadChildren: () =>
            import('./planning/replenishment/replenishment.module').then(m => m.ReplenishmentModule)
      },
      // Shipment-order-load
      {
        path: 'shipment-order-load',
        loadChildren: () =>
          import('./planning/shipment-order-load/shipment-order-load.module').then(m => m.ShipmentOrderLoadModule)
      },
      {
        path: 'shipment-order-load/add',
        loadChildren: () =>
          import('./planning/shipment-order-load/add/shipment-order-load-add.module').then(m => m.ShipmentOrderLoadAddModule)
      },
      {
        path: 'shipment-order-load/:id/edit',
        loadChildren: () =>
          import('./planning/shipment-order-load/edit/shipment-order-load-edit.module').then(m => m.ShipmentOrderLoadEditModule)
      },
      {
        path: 'shipment-order-load/id/:id/details',
        loadChildren: () =>
          import('./planning/shipment-order-load/detail/shipment-order-load-detail.module').then(m => m.ShipmentOrderLoadDetailModule)
      },
      {
        path: 'shipment-order-load/id/:id/details/add',
        loadChildren: () =>
          import('./planning/shipment-order-load/detail/add/shipment-order-load-detail-add.module').then(m =>
            m.ShipmentOrderLoadDetailAddModule)
      },
      {
        path: 'shipment-order-load/id/:id/details/edit/:detailId',
        loadChildren: () =>
          import('./planning/shipment-order-load/detail/edit/shipment-order-load-detail-edit.module').then(m =>
            m.ShipmentOrderLoadDetailEditModule)
      },
      // // Picking-Pallet
      {
        path: 'picking-pallet', loadChildren: () =>
          import('./process/picking-pallet/picking-pallet.module').then(m => m.PickingPalletModule)
      },
      {
        path: 'picking-pallet/:id', loadChildren: () =>
          import('./process/picking-pallet/picking-pallet.module').then(m => m.PickingPalletModule)
      },
      // // Picking-Pallet-Working-Order
      {
        path: 'picking-pallet-working-order',
        loadChildren: () =>
          import('./process/picking-pallet-working-order/picking-pallet-working-order.module').then(m =>
            m.PickingPalletWorkingOrderModule)
      },
      {
        path: 'picking-pallet-working-order/:id',
        loadChildren: () =>
          import('./process/picking-pallet-working-order/picking-pallet-working-order.module').then(m =>
            m.PickingPalletWorkingOrderModule)
      },
      // // Attribute Change
      {
        path: 'inventory-control/attribute-change',
        loadChildren: () =>
          import('./process/inventory-control/attribute-change/attribute-change.module').then(m => m.AttributeChangeModule)
      },
      // // Programmed-cycle-count
      {
        path: 'programmed-cycle-count',
        loadChildren: () =>
          import('./inventory/programmed-cycle-count/programmed-cycle-count.module').then(m => m.ProgrammedCycleCountModule)
      },
      // // Generate-count
      {
        path: 'generate-count',
        loadChildren: () =>
          import('./inventory/generate-count/generate-count.module').then(m => m.GenerateCountModule)
      },
      // // Serial traceability
      {
        path: 'inventory/serial-traceability',
        loadChildren: () =>
          import('./inventory/serial-traceability/serial-traceability.module').then(m => m.SerialTraceabilityModule)
      },
      // // Shipment
      {
        path: 'shipment', loadChildren: () =>
          import('./process/shipment/shipment.module').then(m => m.ShipmentModule)
      },
      {
        path: 'shipment/add', loadChildren: () =>
          import('./process/shipment/add/shipment-add.module').then(m => m.ShipmentAddModule)
      },
      {
        path: 'shipment/edit/:id', loadChildren: () =>
          import('./process/shipment/edit/shipment-edit.module').then(m => m.ShipmentEditModule)
      },
      {
        path: 'shipment/shipment-detail/:id', loadChildren: () =>
          import('./process/shipment/detail/shipment-detail.module').then(m => m.ShipmentDetailModule)
      },
      {
        path: 'shipment/shipment-detail/:id/detail',
        loadChildren: () =>
          import('./process/shipment/detail/shipping-detail/shipping-detail.module').then(m => m.ShippingDetailModule)
      },
      {
        path: 'shipment/close/:id', loadChildren: () =>
          import('./process/shipment/close/shipment-close.module').then(m => m.ShipmentCloseModule)
      },
      {
        path: 'shipment/close/:shipment_id/detail/:id',
        loadChildren: () =>
          import('./process/shipment/close/detail/shipment-close-detail.module').then(m => m.ShipmentCloseDetailModule)
      },
      {
        path: 'shipment/process', loadChildren: () =>
          import('./process/shipment/process/shipment-process.module').then(m => m.ShipmentProcessModule)
      },
      // // NEW-RECEIPT-RF
      {
        path: 'receipt-rf',
        loadChildren: () =>
          import('./process/receipt/receipt-process/receipt-rf.module').then(m => m.ReceiptRfModule)
      },
      // /* RECEIPT ORDER*/
      {
        path: 'receipt/receipt-order', loadChildren: () =>
          import('./process/receipt/receipt-order/receipt-order.module').then(m => m.ReceiptOrderModule)
      },
      {
        path: 'receipt/receipt-order/receipt-order-detail/:id',
        loadChildren: () =>
          import('./process/receipt/receipt-order/receipt-order-detail/receipt-order-detail.module').then(m =>
        m.ReceiptOrderDetailModule)
      },
      {
        path: 'receipt/receipt-order/add',
        loadChildren: () =>
          import('./process/receipt/receipt-order/receipt-order-add/receipt-order-add.module').then(m => m.ReceiptOrderAddModule)
      },
      {
        path: 'receipt/receipt-order/edit/:id',
        loadChildren: () =>
          import('./process/receipt/receipt-order/receipt-order-edit/receipt-order-edit.module').then(m => m.ReceiptOrderEditModule)
      },
      {
        path: 'receipt/receipt-order/edit/:id/detail/:detailId',
        loadChildren: () =>
        import('./process/receipt/receipt-order/receipt-order-edit/receipt-order-edit-detail/receipt-order-edit-detail.module')
        .then(m => m.ReceiptOrderEditDetailModule)
      },
      {
        path: 'receipt/receipt-order/edit/:id/detail',
        loadChildren: () =>
        import('./process/receipt/receipt-order/receipt-order-edit/receipt-order-edit-detail/receipt-order-edit-detail.module')
        .then(m => m.ReceiptOrderEditDetailModule)
      },
      // /* TRUCK-ARRIVAL */
      {
        path: 'truck-arrival', loadChildren: () =>
          import('./process/receipt/truck-arrival/truck-arrival.module').then(m => m.TruckArrivalModule)
      },
      {
        path: 'truck-arrival/add',
        loadChildren: () =>
          import('./process/receipt/truck-arrival/truck-arrival-add/truck-arrival-add.module').then(m => m.TruckArrivalAddModule)
      },
      {
        path: 'truck-arrival/edit/:id',
        loadChildren: () =>
          import('./process/receipt/truck-arrival/truck-arrival-edit/truck-arrival-edit.module').then(m => m.TruckArrivalEditModule)
      },
      // /* RECEIPT APPOINTMENT */
      { path: 'receipt/receipt-appointment-calendar',
        loadChildren: () => import('./process/receipt/receipt-appointment/receipt-appointment-calendar/receipt-appointment-calendar.module')
        .then(m => m.ReceiptAppointmentCalendarModule) },
      {
        path: 'receipt-appointment/add',
        loadChildren: () => import('./process/receipt/receipt-appointment/receipt-appointment-agenda/receipt-appointment-agenda.module')
        .then(m => m.ReceiptAppointmentAgendaModule)
      },
      { path: 'receipt-appointment',
        loadChildren: () => import('./process/receipt/receipt-appointment/receipt-appointment.module')
        .then(m => m.ReceiptAppointmentModule)
      },
      {
        path: 'receipt-appointment/edit/:id',
        loadChildren: () =>
        import('./process/receipt/receipt-appointment/receipt-appointment-agenda-edit/receipt-appointment-agenda-edit.module')
        .then(m => m.ReceiptAppointmentAgendaEditModule)
      },
      {
        path: 'receipt-appointment/detail/:id',
        loadChildren: () => import('./process/receipt/receipt-appointment/receipt-appointment-detail/receipt-appointment-detail.module')
        .then(m => m.ReceiptAppointmentDetailModule)
      },
      /* RECEIPT PER PIECE */
      {
        path: 'receipt/receipt-pieces', loadChildren: () =>
          import('./process/receipt/receipt-pieces/receipt-pieces.module').then(m => m.ReceiptPiecesModule)
      },
      // /* Receipt Polices */
      {
        path: 'receipt-policy',
        loadChildren: () =>
          import('./process/receipt/receipt-policies/receipt-policies.module').then(m => m.ReceiptPoliciesModule)
      },
      {
        path: 'receipt-policy/add',
        loadChildren: () =>
          import('./process/receipt/receipt-policies/receipt-policies-add/receipt-policies-add.module').then(m =>
            m.ReceiptPoliciesAddModule)
      },
      {
        path: 'receipt-policy/edit/:id',
        loadChildren: () =>
          import('./process/receipt/receipt-policies/receipt-policies-edit/receipt-policies-edit.module').then(m =>
            m.ReceiptPoliciesEditModule)
      },
      // /* MOVEMENT PATH*/
      {
        path: 'movement-path', loadChildren: () =>
          import('./administration/movement-path/movement-path.module').then(m => m.MovementPathModule)
      },
      {
        path: 'movement-path/detail/:id',
        loadChildren: () =>
          import('./administration/movement-path/detail/movement-path-detail.module').then(m => m.MovementPathDetailModule)
      },
      {
        path: 'movement-path/add',
        loadChildren: () =>
          import('./administration/movement-path/add/movement-path-add.module').then(m => m.MovementPathAddModule)
      },
      {
        path: 'movement-path/edit/:id',
        loadChildren: () =>
          import('./administration/movement-path/edit/movement-path-edit.module').then(m => m.MovementPathEditModule)
      },
      /* PACK STATION*/
      {
        path: 'pack-station',
        loadChildren: () =>
          import('./pack-station/pack-station.module').then(m => m.PackStationModule)
      },
      {
        path: 'pack-station-report',
        loadChildren: () =>
          import('./pack-station/pack-station-report/pack-station-report.module').then(m => m.PackStationReportModule)
      },
      {
        path: 'pack-station-process',
        loadChildren: () =>
          import('./pack-station/pack-station-process/pack-station-process.module').then(m => m.PackStationProcessModule)
      },
      {
        path: 'package-shipment',
        loadChildren: () =>
          import('./pack-station/package-shipment/package-shipment.module').then(m => m.PackageShipmentModule)
      },
      {
        path: 'package-shipment-process',
        loadChildren: () =>
          import('./pack-station/package-shipment/package-shipment-process/package-shipment-process.module').then(m =>
            m.PackageShipmentProcessModule)
      },
      {
        path: 'generate-guide-config',
        loadChildren: () =>
          import('./pack-station/generate-guide-config/generate-guide-config.module').then(m =>
            m.GenerateGuideConfigModule)
      },
      {
        path: 'api/add/:accountId', loadChildren: () =>
          import('./pack-station/generate-guide-config/api/api-add/api-add.module').then(m => m.ApiAddModule)
      },
      {
        path: 'api/edit/:id/:accountId', loadChildren: () =>
          import('./pack-station/generate-guide-config/api/api-edit/api-edit.module').then(m => m.ApiEditModule)
      },
      // /* BULK PICKING */
      {
        path: 'bulk-picking-process/:lpn',
        loadChildren: () =>
          import('./process/bulk-picking/process/bulk-picking-process.module').then(m => m.BulkPickingProcessModule)
      },
      /* ASSIGNED TASK */
      {
        path: 'assigned-task',
        loadChildren: () =>
          import('./process/assigned-task/assigned-task.module').then(m => m.AssignedTaskModule)
      },
      /* LABEL CONFIGURATION */
      {
        path: 'label-configuration',
        loadChildren: () =>
          import('./administration/label-configuration/label-configuration.module').then(m => m.LabelConfigurationModule)
      },
      {
        path: 'label-configuration-add',
        loadChildren: () =>
          import('./administration/label-configuration/add/label-configuration-add.module').then(m => m.LabelConfigurationAddModule)
      },
      {
        path: 'label-configuration-edit/:id',
        loadChildren: () =>
          import('./administration/label-configuration/edit/label-configuration-edit.module').then(m => m.LabelConfigurationEditModule)
      },
      {
        path: 'interface-configuration',
        loadChildren: () => import('./administration/interface-configuration/interface-configuration.module').then(m => m.InterfaceConfigurationModule)
      },
      {
        path: 'interface-configuration/add',
        loadChildren: () => import('./administration/interface-configuration/add/interface-configuration-add.module').then(m => m.InterfaceConfigurationAddModule)
      },
      {
        path: 'interface-configuration/edit/:id',
        loadChildren: () => import('./administration/interface-configuration/add/interface-configuration-add.module').then(m => m.InterfaceConfigurationAddModule)
      },
      /* Order preparation process for rf */
      {
        path: 'order-preparation-process-rf',
        loadChildren: () =>
          import('./process/rf/order-preparation-process-rf/order-preparation-process-rf.module')
            .then(m => m.OrderPreparationProcessRfModule)
      },
      // Task type priority
      { path: 'task-type-priority', loadChildren: () => import('./administration/task-type-priority/task-type-priority.module')
        .then(m => m.TaskTypePriorityModule) },
        // First access - Change password
      { path: 'change-password', loadChildren: () =>
        import('./administration/change-password/change-password.module').then(m => m.ChangePasswordModule) },
      { path: '', redirectTo: '/home', pathMatch: 'full' },
      /* Audit */
      {
        path: 'audit',
        loadChildren: () =>
          import('./inventory/audit/audit.module').then(m => m.AuditModule)
      },
      {
        path: 'report-audit',
        loadChildren: () =>
          import('./inventory/report-audit/report-audit.module').then(m => m.ReportAuditModule)
      },
      {
        path: 'view-detail-audit/:lpn/:account',
        loadChildren: () =>
          import('./inventory/view-detail-audit/view-detail-audit.module').then(m => m.ViewDetailAuditModule)
      },
      /* Store */
      {
        path: 'store', loadChildren: () =>
          import('./catalog/store/store.module').then(m => m.StoreModule)
      },
      {
        path: 'store/add', loadChildren: () =>
          import('./catalog/store/add/store-add.module').then(m => m.StoreAddModule)
      },
      {
        path: 'store/edit/:id', loadChildren: () =>
          import('./catalog/store/edit/store-edit.module').then(m => m.StoreEditModule)
      },
      // Picking by each
      { path: 'picking-by-each', loadChildren: () =>
          import('./process/picking-by-each/picking-by-each.module').then(m => m.PickingByEachModule)
      },
      { path: 'picking-by-each-process', loadChildren: () =>
          import('./process/picking-by-each/picking-by-each-process/picking-by-each-process.module').then(m => m.PickingByEachProcessModule)
      },
      // Concepts
      {
        path: 'concepts', loadChildren: () =>
          import('./catalog/concept/concept.module').then(m => m.ConceptsModule)
      },
      {
        path: 'concepts/add', loadChildren: () =>
          import('./catalog/concept/add/concept-add.module').then(m => m.ConceptAddModule)
      },
      {
        path: 'concepts/edit/:id', loadChildren: () =>
          import('./catalog/concept/edit/concept-edit.module').then(m => m.ConceptEditModule)
      }
    ]
  },
  // IMPORTANT This part contains only the main routes of the project.
  { path: 'login', component: LoginComponent, resolve: { systemLabels: SystemLabelsResolver } },
  { path: '**', component: PageNotFoundComponent }
];
export const APP_ROUTES = RouterModule.forRoot(appRoutes, { scrollPositionRestoration: 'enabled' });
